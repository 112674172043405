import React from 'react';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { EGDSLayoutFlex, EGDSLayoutFlexItem, EGDSSpacing, EGDSSpacingHr } from '@egds/react-core';
import { DirectWord } from '../layout/direct-word';
import { FooterCopyright } from './footer-copyright';
import { FooterLinks } from './footer-links';
function Footer(_a) {
  var includeCurrencySelector = _a.includeCurrencySelector;
  return React.createElement(EGDSSpacing, {
    padding: {
      block: 'four'
    },
    margin: {
      blockstart: 'twelve'
    }
  }, React.createElement("footer", {
    className: "Footer",
    "data-testid": "footer"
  }, React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement("div", {
    className: "container"
  }, React.createElement(EGDSLayoutFlex, {
    direction: "row",
    alignContent: "center",
    justifyContent: "space-between"
  }, React.createElement(DirectWord, {
    showIcon: true,
    display: "link-small"
  }), includeCurrencySelector && null /*
                                      <DialogProviderShell>
                                      <ReportCurrencySelector variant="history" />
                                      </DialogProviderShell>
                                      */), React.createElement(EGDSSpacingHr, null), React.createElement(EGDSLayoutFlex, {
    direction: "row",
    alignContent: "center",
    justifyContent: "space-between"
  }, React.createElement(EGDSLayoutFlexItem, null, React.createElement(FooterLinks, null)), React.createElement(EGDSLayoutFlexItem, null, React.createElement(FooterCopyright, null))))), React.createElement(ViewSmall, null, React.createElement("div", {
    className: "container"
  }, React.createElement(EGDSLayoutFlex, {
    direction: "column",
    alignContent: "center",
    justifyContent: "space-between"
  }, React.createElement(DirectWord, {
    showIcon: true,
    display: "link-small"
  }), includeCurrencySelector && null /*
                                      <DialogProviderShell>
                                      <ReportCurrencySelector variant="history" />
                                      </DialogProviderShell>
                                      */), React.createElement(EGDSSpacingHr, null), React.createElement(EGDSLayoutFlex, {
    direction: "column",
    alignContent: "center",
    justifyContent: "space-between",
    space: "four"
  }, React.createElement(EGDSLayoutFlexItem, null, React.createElement(FooterLinks, null)), React.createElement(EGDSLayoutFlexItem, null, React.createElement(FooterCopyright, null))))))));
}
export { Footer };