export function getLocalizedFormLabel(title, field_name, bank_country, l10n) {
  switch (title) {
    case 'saving account':
      return l10n.formatText('bank-details.form.label.saving_account') || 'Saving account';
    case 'current account':
      return l10n.formatText('bank-details.form.label.current_account') || 'Current account';
    case 'country':
      return l10n.formatText('bank-details.form.label.country');
    case 'preferred_currency':
      return l10n.formatText('bank-details.form.label.preferred_currency');
    case 'account_name':
      return l10n.formatText('bank-details.form.label.account_name');
    case 'bank_name':
      return l10n.formatText('bank-details.form.label.bank_name');
    case 'bank_address':
      return l10n.formatText('bank-details.form.label.bank_address');
    case 'bank_number':
      return l10n.formatText('bank-details.form.label.bank_number');
    case 'branch_number':
      return l10n.formatText('bank-details.form.label.branch_number');
    case 'katakana_account_name':
      return l10n.formatText('bank-details.form.label.katakana_name');
    case 'telephone':
      return l10n.formatText('bank-details.form.label.telephone');
    case 'account_type':
      return l10n.formatText('bank-details.form.label.account_type');
    case 'account_number':
      switch (bank_country) {
        case 'MX':
          return l10n.formatText('bank-details.form.label.clabe');
        default:
          return l10n.formatText('bank-details.form.label.account_number');
      }
    case 'cpfcnpj':
      return l10n.formatText('bank-details.form.label.cpfcnpj');
    case 'iban':
      return l10n.formatText('bank-details.form.label.iban');
    case 'swift_code':
      return l10n.formatText('bank-details.form.label.swift_code');
    case 'sort_code':
      return l10n.formatText('bank-details.form.label.sort_code');
    case 'routing_number':
      switch (bank_country) {
        case 'US':
          return l10n.formatText('bank-details.form.label.routing_number');
        case 'AU':
          return l10n.formatText('bank-details.form.label.bsb_number');
        case 'CA':
          return l10n.formatText('bank-details.form.label.transit_number');
        case 'JP':
          return l10n.formatText('bank-details.form.label.branch_name_zengin_code');
        case 'SG':
          return l10n.formatText('bank-details.form.label.clearing_code');
        default:
          return field_name;
      }
    default:
      return field_name;
  }
}