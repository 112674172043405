import { DASHBOARD_PATH } from 'features/app/dashboard/dashboard.controller';
import { LINK_BUILDER_PATH } from 'features/app/products/link-builder/link-builder.controller';
import { TRAVEL_VIDEO_PATH } from 'features/travel-video/travel-video.controller';
import { Badge } from 'shared/components/badges/badge';
import { Condition, useConditions } from 'shared/conditions/use-conditions';
export var navItemsList = function () {
  var conditions = useConditions();
  return [{
    section: 'misc',
    items: [{
      name: 'Application',
      icon: 'assignment',
      l10nKey: 'application.page.title',
      href: '/application'
    }, {
      name: 'Home',
      icon: 'home',
      l10nKey: 'common.home',
      href: DASHBOARD_PATH
    }, {
      name: 'performance',
      enabled: true,
      icon: 'trending_up',
      l10nKey: 'common.performance',
      href: '/performance'
    }, {
      name: 'CommissionReport',
      enabled: conditions[Condition.CommissionTransactions],
      icon: 'monetization_on',
      text: 'Commission report',
      href: '/transaction'
    }, {
      name: 'Payouts',
      enabled: conditions[Condition.Payouts],
      icon: 'payments',
      l10nKey: 'payouts.page.menu',
      href: '/payouts'
    }, {
      name: 'History',
      icon: 'history',
      l10nKey: 'product.link-history.title',
      href: '/link-history'
    }]
  }, {
    section: 'tools',
    titleKey: 'dashboard.common.tools',
    items: [{
      name: 'CreatorToolbox',
      enabled: conditions[Condition.CreatorToolbox],
      icon: 'toolbox',
      l10nKey: 'product.creator-toolbox.title',
      href: '/products/creator-toolbox'
    }, {
      name: 'TravelShops',
      enabled: conditions[Condition.TravelShopsEnabled],
      text: 'Travel Shops',
      icon: 'local_shopping',
      href: '/products/travel-shops',
      badge: Badge.BETA
    }, {
      name: 'TravelVideo',
      enabled: conditions[Condition.TravelVideos],
      text: 'Travel Videos',
      icon: 'ondemand_entertainment',
      href: TRAVEL_VIDEO_PATH,
      // this is /products/travel-video in the other app
      l10nKey: 'travelvideos.header',
      badge: Badge.BETA
    }, {
      name: 'LinkBuilder',
      icon: 'add_circle_outline',
      l10nKey: 'product.link-builder.title',
      href: LINK_BUILDER_PATH
    }, {
      name: 'Search',
      icon: 'search',
      l10nKey: 'products.search.title',
      href: '/products/search'
    }, {
      name: 'Banners',
      icon: 'map',
      l10nKey: 'products.banners.title',
      href: '/products/banners'
    }]
  }];
};