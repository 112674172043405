var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { usePlatformContext } from '@bernie/platform-context';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkMenu, UitkMenuContainer, UitkMenuList, UitkMenuTrigger } from 'uitk-react-menu';
import { UitkText } from 'uitk-react-text';
import { supportedLocales as defaultLocales } from 'shared/config/supported-locales';
export var LanguageSelector = function (_a) {
  var subSupportedLocales = _a.subSupportedLocales,
    _b = _a.onClick,
    onClick = _b === void 0 ? function () {} : _b,
    compact = _a.compact;
  var context = usePlatformContext().context;
  var contextLocale = context.locale;
  var location = useLocation();
  var supportedLocales = subSupportedLocales || defaultLocales;
  var currentLang = React.useMemo(function () {
    var _a;
    return (_a = supportedLocales.find(function (locale) {
      return locale.langCode === contextLocale;
    })) !== null && _a !== void 0 ? _a : supportedLocales[0];
  }, [supportedLocales, contextLocale]);
  // Set state
  var _c = __read(React.useState(currentLang === null || currentLang === void 0 ? void 0 : currentLang.langLabel), 2),
    activeLabel = _c[0],
    setActiveLabel = _c[1];
  var _d = __read(React.useState(false), 2),
    open = _d[0],
    setOpen = _d[1];
  // But we already put it in a cookie in the context...
  React.useEffect(function () {
    localStorage.setItem('locale', contextLocale);
    setActiveLabel(currentLang.langLabel);
  }, [contextLocale, currentLang]);
  var langList = supportedLocales.map(function (lang, i) {
    return {
      attributes: {
        role: 'menuitem',
        tabindex: i + 1
      },
      label: lang.langLabel,
      className: 'uitk-list-item ' + (currentLang.langId === lang.langId ? 'langSelected' : ''),
      href: "".concat(location.pathname, "?locale=").concat(lang.langCode),
      typeAnchor: true,
      onClick: function (e) {
        return handleItemClick(e, lang.langLabel);
      },
      analytics: {
        id: 'affhub_language_switcher.submitted',
        callback: function () {
          analyticsTrackEvents('affhub_language_switcher.submitted');
        }
      }
    };
  });
  function handleItemClick(e, label) {
    setActiveLabel(label);
    setOpen(false);
  }
  function handleTriggerClicked() {
    setOpen(!open);
  }
  var analyticsTrackEvents = function (rfrr) {
    // analyticsEvent(analytics, userAuth, pzStore, rfrr, linkname);
    console.log(rfrr);
  };
  return React.createElement(UitkMenu, {
    isOpen: open,
    onTriggerClick: handleTriggerClicked
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkTertiaryButton, {
    onClick: onClick,
    "data-stid": "language-selector"
  }, React.createElement(UitkIcon, {
    name: "language",
    className: "Language__icon"
  }), !compact && React.createElement(UitkText, {
    size: 300,
    className: "visible-desktop--large"
  }, activeLabel))), React.createElement(UitkMenuContainer, {
    autoPosition: true
  }, React.createElement(UitkMenuList, {
    items: langList
  })));
};