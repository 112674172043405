import React from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { UitkIcon } from 'uitk-react-icons';
import { AccountNav } from '../account-nav/account-nav';
export function TopBarMobile(_a) {
  var setSideBar = _a.setSideBar,
    landingUrl = _a.landingUrl;
  return React.createElement("header", {
    className: "TopBar"
  }, React.createElement("div", {
    className: "TopBar__header"
  }, setSideBar && React.createElement("button", {
    className: "TopBar__header--toggle",
    onClick: setSideBar,
    style: {
      background: 'none'
    },
    title: "menu"
  }, React.createElement(UitkIcon, {
    name: "menu"
  })), React.createElement(Link, {
    to: landingUrl
  }, React.createElement("img", {
    src: "/images/logos/logo-white-mobile.png",
    className: "TopBar__logo",
    alt: "Expedia Group Affiliate Program"
  }))), React.createElement("div", {
    className: "TopBar__nav"
  }, React.createElement(AccountNav, null)));
}