import { APP_NAME, EventType } from 'shared/utils/click-stream-events';
import { ProfileController } from './profile/profile.controller';
var controller = new ProfileController();
export function accountTabSelected(_a) {
  var eventName = _a.eventName;
  return {
    event: {
      event_version: '1.0.0',
      event_name: eventName,
      event_type: EventType.INTERACTION,
      action_location: controller.path
    },
    experience: {
      page_name: controller.pageId,
      app_name: APP_NAME
    }
  };
}