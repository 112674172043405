var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useState } from 'react';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { classNames } from 'uitk-react-utils';
import { Footer } from 'shared/components/footer/footer';
import { DesktopSideBar } from './components/side-bar/desktop-side-bar';
import { MobileSideBar } from './components/side-bar/mobile-side-bar';
import { TopBarDesktop } from './components/top-bar/top-bar-desktop';
import { TopBarMobile } from './components/top-bar/top-bar-mobile';
export function AuthenticatedLayout(_a) {
  var children = _a.children,
    enableSideBar = _a.enableSideBar;
  var showSideBar = enableSideBar !== null && enableSideBar !== void 0 ? enableSideBar : true;
  var _b = __read(useState(showSideBar), 2),
    desktopSidebarOpen = _b[0],
    setDesktopSidebarOpen = _b[1];
  var _c = __read(useState(false), 2),
    mobileSidebarOpen = _c[0],
    setMobileSidebarOpen = _c[1];
  var landingUrl = '';
  function Main() {
    return React.createElement("main", {
      className: classNames('AuthenticatedLayout__content', desktopSidebarOpen ? 'AuthenticatedLayout__content--sideBarOpen' : 'AuthenticatedLayout__content--sideBarClosed'),
      style: {
        minHeight: 'calc(100vh - 60px)'
      }
    }, React.createElement("div", null, children), React.createElement(Footer, {
      includeCurrencySelector: false /* TODO includeCurrencySelector*/
    }));
  }
  return React.createElement("div", {
    className: "AuthenticatedLayout",
    "data-stid": "AuthenticatedLayout"
  }, React.createElement("section", {
    className: "AuthenticatedLayout__body"
  }, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(React.Fragment, null, React.createElement(TopBarMobile, {
    setSideBar: showSideBar && function () {
      return setMobileSidebarOpen(!mobileSidebarOpen);
    },
    landingUrl: landingUrl
  }), React.createElement(MobileSideBar, {
    open: showSideBar && mobileSidebarOpen,
    onClick: function () {
      return setMobileSidebarOpen(false);
    }
  }), React.createElement(Main, null))), React.createElement(ViewLarge, null, React.createElement(React.Fragment, null, React.createElement(TopBarDesktop, {
    landingUrl: landingUrl
  }), React.createElement(DesktopSideBar, {
    open: showSideBar && desktopSidebarOpen,
    onClick: function () {
      return setDesktopSidebarOpen(!desktopSidebarOpen);
    }
  }), React.createElement(Main, null))))));
}