import { useEffect } from 'react';
export var TagCommanderLoader = function (_a) {
  var environment = _a.environment;
  useEffect(function () {
    var _a;
    var tcs = document.createElement('script');
    tcs.type = 'text/javascript';
    tcs.onload = function () {
      var _a;
      console.log('TagCommander script loaded:', {
        tcExists: !!window.tC,
        tcObject: window.tC,
        tcEvents: ((_a = window.tC) === null || _a === void 0 ? void 0 : _a.event) ? Object.keys(window.tC.event) : 'No events'
      });
      window.tcLoaded = true;
    };
    tcs.onerror = function (error) {
      console.error('Error loading TagCommander script:', error);
    };
    if (environment === 'production') {
      tcs.src = 'https://cdn.tagcommander.com/6035/tc_vrbo_prod.js';
    } else {
      tcs.src = 'https://cdn.tagcommander.com/6026/tc_vrbo_lab.js';
    }
    console.log('Loading TC script:', tcs.src);
    var firstScript = document.getElementsByTagName('script')[0];
    (_a = firstScript === null || firstScript === void 0 ? void 0 : firstScript.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(tcs, firstScript);
  }, [environment]);
  return null;
};