import React from 'react';
import { classNames } from 'uitk-react-utils';
import { NavMenu } from '../nav-menu/nav-menu';
export function MobileSideBar(_a) {
  var open = _a.open,
    onClick = _a.onClick;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: classNames('SideBar', 'SideBar__mobile', open ? 'SideBar__mobile--open' : 'SideBar__mobile--closed'),
    "data-stid": "nav-links"
  }, React.createElement("nav", {
    className: "SideBar__content"
  }, React.createElement(NavMenu, {
    open: open
  }))), open && React.createElement("div", {
    role: "dialog",
    className: "SideBar__mobileScrim",
    onClick: onClick
  }));
}