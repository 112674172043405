import React from 'react';
import { EGDSBanner } from '@egds/react-core/banner';
import { EGDSIcon } from '@egds/react-core/icons';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSHeading, EGDSText } from '@egds/react-core/text';
export function ErrorBanner(_a) {
  var title = _a.title,
    message = _a.message;
  return React.createElement(EGDSBanner, {
    theme: "error"
  }, React.createElement(EGDSLayoutFlex, {
    alignItems: "center",
    space: "two"
  }, React.createElement(EGDSLayoutFlexItem, null, React.createElement("div", null, React.createElement(EGDSIcon, {
    name: "error",
    spotlight: {
      theme: 'standard',
      background: false
    }
  }))), React.createElement(EGDSLayoutFlexItem, null, React.createElement("div", null, title && React.createElement(EGDSHeading, {
    size: 7,
    tag: "h2"
  }, title), React.createElement(EGDSText, null, message)))));
}