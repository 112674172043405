export function getCountries(locale) {
  var _a, _b;
  var defaultLocale = 'en-us';
  var formattedLocale = (_a = locale === null || locale === void 0 ? void 0 : locale.toLowerCase().replace('_', '-')) !== null && _a !== void 0 ? _a : defaultLocale;
  var countryList = new Map(Object.entries((_b = localeCountriesMap[formattedLocale]) !== null && _b !== void 0 ? _b : localeCountriesMap[defaultLocale]));
  return countryList;
}
export var localeCountriesMap = {
  'en-us': {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    SO: 'Federal Republic of Somalia',
    FM: 'Federated States of Micronesia',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern and Antarctic Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard and McDonald Islands',
    HN: 'Honduras',
    HK: 'Hong Kong SAR',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau SAR',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    CG: 'Republic of the Congo',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    MF: 'Saint Martin',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé and Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    SH: 'St. Helena',
    KN: 'St. Kitts and Nevis',
    LC: 'St. Lucia',
    PM: 'St. Pierre and Miquelon',
    VC: 'St. Vincent and the Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Türkiye',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos',
    TV: 'Tuvalu',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States of America',
    UY: 'Uruguay',
    UM: 'US Minor Outlying Islands',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis and Futuna',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  },
  'en-gb': {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    SO: 'Federal Republic of Somalia',
    FM: 'Federated States of Micronesia',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern and Antarctic Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard and McDonald Islands',
    HN: 'Honduras',
    HK: 'Hong Kong SAR',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau SAR',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    CG: 'Republic of the Congo',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    MF: 'Saint Martin',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé and Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    SH: 'St. Helena',
    KN: 'St. Kitts and Nevis',
    LC: 'St. Lucia',
    PM: 'St. Pierre and Miquelon',
    VC: 'St. Vincent and the Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Türkiye',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos',
    TV: 'Tuvalu',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States of America',
    UY: 'Uruguay',
    UM: 'US Minor Outlying Islands',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis and Futuna',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  },
  'en-ca': {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    SO: 'Federal Republic of Somalia',
    FM: 'Federated States of Micronesia',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern and Antarctic Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard and McDonald Islands',
    HN: 'Honduras',
    HK: 'Hong Kong SAR',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau SAR',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    CG: 'Republic of the Congo',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    MF: 'Saint Martin',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé and Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    SH: 'St. Helena',
    KN: 'St. Kitts and Nevis',
    LC: 'St. Lucia',
    PM: 'St. Pierre and Miquelon',
    VC: 'St. Vincent and the Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Türkiye',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos',
    TV: 'Tuvalu',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States of America',
    UY: 'Uruguay',
    UM: 'US Minor Outlying Islands',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis and Futuna',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  },
  'en-au': {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    SO: 'Federal Republic of Somalia',
    FM: 'Federated States of Micronesia',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern and Antarctic Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard and McDonald Islands',
    HN: 'Honduras',
    HK: 'Hong Kong SAR',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau SAR',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    CG: 'Republic of the Congo',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    MF: 'Saint Martin',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé and Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    SH: 'St. Helena',
    KN: 'St. Kitts and Nevis',
    LC: 'St. Lucia',
    PM: 'St. Pierre and Miquelon',
    VC: 'St. Vincent and the Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Türkiye',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos',
    TV: 'Tuvalu',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States of America',
    UY: 'Uruguay',
    UM: 'US Minor Outlying Islands',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis and Futuna',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  },
  'en-nz': {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    SO: 'Federal Republic of Somalia',
    FM: 'Federated States of Micronesia',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern and Antarctic Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard and McDonald Islands',
    HN: 'Honduras',
    HK: 'Hong Kong SAR',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau SAR',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    CG: 'Republic of the Congo',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    MF: 'Saint Martin',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé and Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    SH: 'St. Helena',
    KN: 'St. Kitts and Nevis',
    LC: 'St. Lucia',
    PM: 'St. Pierre and Miquelon',
    VC: 'St. Vincent and the Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Türkiye',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos',
    TV: 'Tuvalu',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States of America',
    UY: 'Uruguay',
    UM: 'US Minor Outlying Islands',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis and Futuna',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  },
  'en-sg': {
    ID: 'Indonesia'
  },
  'fr-fr': {
    AF: 'Afghanistan',
    ZA: 'Afrique du Sud',
    AL: 'Albanie',
    DZ: 'Algérie',
    DE: 'Allemagne',
    AD: 'Andorre',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua-et-Barbuda',
    SA: 'Arabie Saoudite',
    AR: 'Argentine',
    AM: 'Arménie',
    AW: 'Aruba',
    AU: 'Australie',
    AT: 'Autriche',
    AZ: 'Azerbaïdjan',
    BS: 'Bahamas',
    BH: 'Bahreïn',
    BD: 'Bangladesh',
    BB: 'Barbade',
    BE: 'Belgique',
    BZ: 'Belize',
    BJ: 'Bénin',
    BM: 'Bermudes',
    BT: 'Bhoutan',
    BY: 'Biélorussie',
    BO: 'Bolivie',
    BQ: 'Bonaire, Saint-Eustache et Saba',
    BA: 'Bosnie-Herzégovine',
    BW: 'Botswana',
    BR: 'Brésil',
    BN: 'Brunei',
    BG: 'Bulgarie',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodge',
    CM: 'Cameroun',
    CA: 'Canada',
    CV: 'Cap-Vert',
    CL: 'Chili',
    CN: 'Chine',
    CY: 'Chypre',
    CO: 'Colombie',
    KM: 'Comores',
    KP: 'Corée du Nord',
    KR: 'Corée du Sud',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatie',
    CU: 'Cuba',
    CW: 'Curaçao',
    DK: 'Danemark',
    DJ: 'Djibouti',
    DM: 'Dominique',
    EG: 'Égypte',
    SV: 'El Salvador',
    AE: 'Émirats arabes unis',
    EC: 'Équateur',
    ER: 'Érythrée',
    ES: 'Espagne',
    EE: 'Estonie',
    SZ: 'Eswatini',
    FM: 'Etats fédérés de Micronésie',
    US: 'États-Unis d’Amérique',
    ET: 'Éthiopie',
    FJ: 'Fidji',
    FI: 'Finlande',
    FR: 'France',
    GA: 'Gabon',
    GM: 'Gambie',
    GE: 'Géorgie',
    GS: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Grèce',
    GD: 'Grenada',
    GL: 'Groenland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernesey',
    GN: 'Guinée',
    GW: 'Guinée Bissao',
    GQ: 'Guinée équatoriale',
    GY: 'Guyana',
    GF: 'Guyane française',
    HT: 'Haïti',
    HN: 'Honduras',
    HU: 'Hongrie',
    BV: 'Île Bouvet',
    CX: 'Île Christmas',
    IM: 'Île de Man',
    MU: 'Île Maurice',
    NF: 'Île Norfolk',
    AX: 'Îles Åland',
    KY: 'Iles Caïmans',
    CC: 'Îles Cocos',
    CK: 'Îles Cook',
    FO: 'Îles Féroé',
    HM: 'Îles Heard-et-MacDonald',
    FK: 'Îles Malouines',
    MH: 'Îles Marshall',
    UM: 'Îles mineures éloignées des États-Unis',
    PN: 'Îles Pitcairn',
    SB: 'Îles Salomon',
    VG: 'Iles Vierges britanniques',
    VI: 'Îles Vierges des États-Unis',
    IN: 'Inde',
    ID: 'Indonésie',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irlande',
    IS: 'Islande',
    IL: 'Israël',
    IT: 'Italie',
    JM: 'Jamaïque',
    JP: 'Japon',
    JE: 'Jersey',
    JO: 'Jordanie',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KI: 'Kiribati',
    KW: 'Koweït',
    RE: 'La Réunion',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Lettonie',
    LB: 'Liban',
    LR: 'Liberia',
    LY: 'Libye',
    LI: 'Liechtenstein',
    LT: 'Lithuanie',
    LU: 'Luxembourg',
    MK: 'Macédoine du Nord',
    MG: 'Madagascar',
    MY: 'Malaisie',
    MW: 'Malawi',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malte',
    MP: 'Mariannes du Nord',
    MA: 'Maroc',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    YT: 'Mayotte',
    MX: 'Mexique',
    MD: 'Moldavie',
    MC: 'Monaco',
    MN: 'Mongolie',
    ME: 'Monténégro',
    MS: 'Montserrat',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibie',
    NR: 'Nauru',
    NP: 'Népal',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigéria',
    NU: 'Niue',
    NO: 'Norvège',
    NC: 'Nouvelle-Calédonie',
    NZ: 'Nouvelle-Zélande',
    OM: 'Oman',
    UG: 'Ouganda',
    UZ: 'Ouzbékistan',
    PK: 'Pakistan',
    PW: 'Palaos',
    PA: 'Panama',
    PG: 'Papouasie-Nouvelle-Guinée',
    PY: 'Paraguay',
    NL: 'Pays-Bas',
    PE: 'Pérou',
    PH: 'Philippines',
    PL: 'Pologne',
    PF: 'Polynésie française',
    PR: 'Porto Rico',
    PT: 'Portugal',
    QA: 'Qatar',
    HK: 'Région administrative spéciale de Hong Kong',
    MO: 'Région administrative spéciale de Macao',
    CF: 'République centrafricaine',
    CD: 'République démocratique du Congo',
    DO: 'République dominicaine',
    CG: 'République du Congo',
    SO: 'République fédérale de Somalie',
    RO: 'Roumanie',
    GB: 'Royaume-Uni',
    RU: 'Russie',
    RW: 'Rwanda',
    BL: 'Saint-Barthélemy',
    KN: 'Saint-Christophe et Niévès',
    SM: 'Saint-Marin',
    MF: 'Saint-Martin',
    PM: 'Saint-Pierre-et-Miquelon',
    VC: 'Saint-Vincent-et-les-Grenadines',
    SH: 'Sainte-Hélène',
    LC: 'Sainte-Lucie',
    WS: 'Samoa',
    AS: 'Samoa américaines',
    ST: 'Sao Tomé-et-Principe',
    SN: 'Sénégal',
    RS: 'Serbie',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapour',
    SX: 'Sint Maarten',
    SK: 'Slovaquie',
    SI: 'Slovénie',
    SD: 'Soudan',
    SS: 'Soudan méridional',
    LK: 'Sri Lanka',
    SE: 'Suède',
    CH: 'Suisse',
    SR: 'Suriname',
    SJ: 'Svalbard',
    SY: 'Syrie',
    TJ: 'Tadjikistan',
    TW: 'Taiwan',
    TZ: 'Tanzanie',
    TD: 'Tchad',
    CZ: 'Tchéquie',
    TF: 'Terres australes et antarctiques françaises',
    IO: "Territoire britannique de l'océan Indien",
    PS: 'Territoires palestiniens',
    TH: 'Thaïlande',
    TL: 'Timor oriental',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinité-et-Tobago',
    TN: 'Tunisie',
    TR: 'Türkiye',
    TM: 'Turkménistan',
    TC: 'Turks et Caïcos',
    TV: 'Tuvalu',
    UA: 'Ukraine',
    UY: 'Uruguay',
    VU: 'Vanuatu',
    VA: 'Vatican',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis et Futuna',
    YE: 'Yémen',
    ZM: 'Zambie',
    ZW: 'Zimbabwe'
  },
  'fr-ca': {
    AF: 'Afghanistan',
    ZA: 'Afrique du Sud',
    AL: 'Albanie',
    DZ: 'Algérie',
    DE: 'Allemagne',
    AD: 'Andorre',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua-et-Barbuda',
    SA: 'Arabie Saoudite',
    AR: 'Argentine',
    AM: 'Arménie',
    AW: 'Aruba',
    AU: 'Australie',
    AT: 'Autriche',
    AZ: 'Azerbaïdjan',
    BS: 'Bahamas',
    BH: 'Bahreïn',
    BD: 'Bangladesh',
    BB: 'Barbade',
    BE: 'Belgique',
    BZ: 'Belize',
    BJ: 'Bénin',
    BM: 'Bermudes',
    BT: 'Bhoutan',
    BY: 'Biélorussie',
    BO: 'Bolivie',
    BQ: 'Bonaire, Saint-Eustache et Saba',
    BA: 'Bosnie-Herzégovine',
    BW: 'Botswana',
    BR: 'Brésil',
    BN: 'Brunei',
    BG: 'Bulgarie',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodge',
    CM: 'Cameroun',
    CA: 'Canada',
    CV: 'Cap-Vert',
    CL: 'Chili',
    CN: 'Chine',
    CY: 'Chypre',
    CO: 'Colombie',
    KM: 'Comores',
    KP: 'Corée du Nord',
    KR: 'Corée du Sud',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatie',
    CU: 'Cuba',
    CW: 'Curaçao',
    DK: 'Danemark',
    DJ: 'Djibouti',
    DM: 'Dominique',
    EG: 'Égypte',
    SV: 'El Salvador',
    AE: 'Émirats arabes unis',
    EC: 'Équateur',
    ER: 'Érythrée',
    ES: 'Espagne',
    EE: 'Estonie',
    SZ: 'Eswatini',
    FM: 'Etats fédérés de Micronésie',
    US: 'États-Unis d’Amérique',
    ET: 'Éthiopie',
    FJ: 'Fidji',
    FI: 'Finlande',
    FR: 'France',
    GA: 'Gabon',
    GM: 'Gambie',
    GE: 'Géorgie',
    GS: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Grèce',
    GD: 'Grenada',
    GL: 'Groenland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernesey',
    GN: 'Guinée',
    GW: 'Guinée Bissao',
    GQ: 'Guinée équatoriale',
    GY: 'Guyana',
    GF: 'Guyane française',
    HT: 'Haïti',
    HN: 'Honduras',
    HU: 'Hongrie',
    BV: 'Île Bouvet',
    CX: 'Île Christmas',
    IM: 'Île de Man',
    MU: 'Île Maurice',
    NF: 'Île Norfolk',
    AX: 'Îles Åland',
    KY: 'Iles Caïmans',
    CC: 'Îles Cocos',
    CK: 'Îles Cook',
    FO: 'Îles Féroé',
    HM: 'Îles Heard-et-MacDonald',
    FK: 'Îles Malouines',
    MH: 'Îles Marshall',
    UM: 'Îles mineures éloignées des États-Unis',
    PN: 'Îles Pitcairn',
    SB: 'Îles Salomon',
    VG: 'Iles Vierges britanniques',
    VI: 'Îles Vierges des États-Unis',
    IN: 'Inde',
    ID: 'Indonésie',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irlande',
    IS: 'Islande',
    IL: 'Israël',
    IT: 'Italie',
    JM: 'Jamaïque',
    JP: 'Japon',
    JE: 'Jersey',
    JO: 'Jordanie',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KI: 'Kiribati',
    KW: 'Koweït',
    RE: 'La Réunion',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Lettonie',
    LB: 'Liban',
    LR: 'Liberia',
    LY: 'Libye',
    LI: 'Liechtenstein',
    LT: 'Lithuanie',
    LU: 'Luxembourg',
    MK: 'Macédoine du Nord',
    MG: 'Madagascar',
    MY: 'Malaisie',
    MW: 'Malawi',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malte',
    MP: 'Mariannes du Nord',
    MA: 'Maroc',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    YT: 'Mayotte',
    MX: 'Mexique',
    MD: 'Moldavie',
    MC: 'Monaco',
    MN: 'Mongolie',
    ME: 'Monténégro',
    MS: 'Montserrat',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibie',
    NR: 'Nauru',
    NP: 'Népal',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigéria',
    NU: 'Niue',
    NO: 'Norvège',
    NC: 'Nouvelle-Calédonie',
    NZ: 'Nouvelle-Zélande',
    OM: 'Oman',
    UG: 'Ouganda',
    UZ: 'Ouzbékistan',
    PK: 'Pakistan',
    PW: 'Palaos',
    PA: 'Panama',
    PG: 'Papouasie-Nouvelle-Guinée',
    PY: 'Paraguay',
    NL: 'Pays-Bas',
    PE: 'Pérou',
    PH: 'Philippines',
    PL: 'Pologne',
    PF: 'Polynésie française',
    PR: 'Porto Rico',
    PT: 'Portugal',
    QA: 'Qatar',
    HK: 'Région administrative spéciale de Hong Kong',
    MO: 'Région administrative spéciale de Macao',
    CF: 'République centrafricaine',
    CD: 'République démocratique du Congo',
    DO: 'République dominicaine',
    CG: 'République du Congo',
    SO: 'République fédérale de Somalie',
    RO: 'Roumanie',
    GB: 'Royaume-Uni',
    RU: 'Russie',
    RW: 'Rwanda',
    BL: 'Saint-Barthélemy',
    KN: 'Saint-Christophe et Niévès',
    SM: 'Saint-Marin',
    MF: 'Saint-Martin',
    PM: 'Saint-Pierre-et-Miquelon',
    VC: 'Saint-Vincent-et-les-Grenadines',
    SH: 'Sainte-Hélène',
    LC: 'Sainte-Lucie',
    WS: 'Samoa',
    AS: 'Samoa américaines',
    ST: 'Sao Tomé-et-Principe',
    SN: 'Sénégal',
    RS: 'Serbie',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapour',
    SX: 'Sint Maarten',
    SK: 'Slovaquie',
    SI: 'Slovénie',
    SD: 'Soudan',
    SS: 'Soudan méridional',
    LK: 'Sri Lanka',
    SE: 'Suède',
    CH: 'Suisse',
    SR: 'Suriname',
    SJ: 'Svalbard',
    SY: 'Syrie',
    TJ: 'Tadjikistan',
    TW: 'Taiwan',
    TZ: 'Tanzanie',
    TD: 'Tchad',
    CZ: 'Tchéquie',
    TF: 'Terres australes et antarctiques françaises',
    IO: "Territoire britannique de l'océan Indien",
    PS: 'Territoires palestiniens',
    TH: 'Thaïlande',
    TL: 'Timor oriental',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinité-et-Tobago',
    TN: 'Tunisie',
    TR: 'Türkiye',
    TM: 'Turkménistan',
    TC: 'Turks et Caïcos',
    TV: 'Tuvalu',
    UA: 'Ukraine',
    UY: 'Uruguay',
    VU: 'Vanuatu',
    VA: 'Vatican',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis et Futuna',
    YE: 'Yémen',
    ZM: 'Zambie',
    ZW: 'Zimbabwe'
  },
  'de-de': {
    AF: 'Afghanistan',
    EG: 'Ägypten',
    AX: 'Ålandinseln',
    AL: 'Albanien',
    DZ: 'Algerien',
    AS: 'Amerikanisch-Samoa',
    VI: 'Amerikanische Jungferninseln',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua und Barbuda',
    GQ: 'Äquatorial-Guinea',
    AR: 'Argentinien',
    AM: 'Armenien',
    AW: 'Aruba',
    AZ: 'Aserbaidschan',
    ET: 'Äthiopien',
    AU: 'Australien',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesch',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgien',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivien',
    BQ: 'Bonaire, Sint Eustatius und Saba',
    BA: 'Bosnien-Herzegowina',
    BW: 'Botsuana',
    BV: 'Bouvet-Insel',
    BR: 'Brasilien',
    VG: 'Britische Jungferninseln',
    IO: 'Britisches Territorium im Indischen Ozean',
    BN: 'Brunei',
    BG: 'Bulgarien',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas-Insel',
    CC: 'Cocos-Inseln',
    CK: 'Cookinseln',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    CW: 'Curaçao',
    DK: 'Dänemark',
    CD: 'Demokratische Republik Kongo',
    DE: 'Deutschland',
    DM: 'Dominica',
    DO: 'Dominikanische Republik',
    DJ: 'Dschibuti',
    EC: 'Ecuador',
    SV: 'El Salvador',
    ER: 'Eritrea',
    EE: 'Estland',
    SZ: 'Eswatini',
    FK: 'Falklandinseln',
    FO: 'Färöer-Inseln',
    FJ: 'Fidschi',
    FI: 'Finnland',
    FR: 'Frankreich',
    GF: 'Französisch-Guayana',
    PF: 'Französisch-Polynesien',
    TF: 'Französische Süd- und Antarktisgebiete',
    GA: 'Gabun',
    GM: 'Gambia',
    GE: 'Georgien',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenada',
    GR: 'Griechenland',
    GL: 'Grönland',
    GB: 'Großbritannien',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haïti',
    HM: 'Heard und McDonald-Inseln',
    HN: 'Honduras',
    IN: 'Indien',
    ID: 'Indonesien',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irland',
    IS: 'Island',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italien',
    JM: 'Jamaika',
    JP: 'Japan',
    YE: 'Jemen',
    JE: 'Jersey',
    JO: 'Jordanien',
    KY: 'Kaimaninseln',
    KH: 'Kambodscha',
    CM: 'Kamerun',
    CA: 'Kanada',
    CV: 'Kap Verde',
    KZ: 'Kasachstan',
    QA: 'Katar',
    KE: 'Kenia',
    KG: 'Kirgisistan',
    KI: 'Kiribati',
    CO: 'Kolumbien',
    KM: 'Komoren',
    HR: 'Kroatien',
    CU: 'Kuba',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Lettland',
    LB: 'Libanon',
    LR: 'Liberia',
    LY: 'Libyen',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxemburg',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Malediven',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marokko',
    MH: 'Marshall-Inseln',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexiko',
    FM: 'Mikronesien',
    MD: 'Moldawien',
    MC: 'Monaco',
    MN: 'Mongolei',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mosambik',
    MM: 'Myanmar (Birma)',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NC: 'Neukaledonien',
    NZ: 'Neuseeland',
    NI: 'Nicaragua',
    NL: 'Niederlande',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    KP: 'Nordkorea',
    MP: 'Nördliche Marianen',
    MK: 'Nordmazedonien',
    NF: 'Norfolk-Insel',
    NO: 'Norwegen',
    OM: 'Oman',
    AT: 'Österreich',
    PK: 'Pakistan',
    PS: 'Palästinensische Gebiete',
    PW: 'Palau',
    PA: 'Panama',
    PG: 'Papua-Neuguinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippinen',
    PN: 'Pitcairninseln',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    CG: 'Republik Kongo',
    SO: 'Republik Somalia',
    RE: 'Réunion',
    RW: 'Ruanda',
    RO: 'Rumänien',
    RU: 'Russland',
    SB: 'Salomonen',
    ZM: 'Sambia',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé und Príncipe',
    SA: 'Saudi-Arabien',
    SE: 'Schweden',
    CH: 'Schweiz',
    SN: 'Senegal',
    RS: 'Serbien',
    SC: 'Seychellen',
    SL: 'Sierra Leone',
    ZW: 'Simbabwe',
    SG: 'Singapur',
    SX: 'Sint Maarten',
    SK: 'Slowakei',
    SI: 'Slowenien',
    HK: 'Sonderverwaltungszone Hongkong',
    MO: 'Sonderverwaltungszone Macau',
    ES: 'Spanien',
    LK: 'Sri Lanka',
    BL: 'St. Barthélemy',
    SH: 'St. Helena',
    KN: 'St. Kitts und Nevis',
    LC: 'St. Lucia',
    MF: 'St. Martin',
    PM: 'St. Pierre und Miquelon',
    VC: 'St. Vincent und die Grenadinen',
    ZA: 'Südafrika',
    SD: 'Sudan',
    GS: 'Südgeorgien und die Südlichen Sandwichinseln',
    KR: 'Südkorea',
    SS: 'Südsudan',
    SR: 'Suriname',
    SJ: 'Svalbard',
    SY: 'Syrien',
    TJ: 'Tadschikistan',
    TW: 'Taiwan',
    TZ: 'Tansania',
    TH: 'Thailand',
    TL: 'Timor Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad und Tobago',
    TD: 'Tschad',
    CZ: 'Tschechien',
    TN: 'Tunesien',
    TR: 'Türkei',
    TM: 'Turkmenistan',
    TC: 'Turks- und Caicos-Inseln',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    HU: 'Ungarn',
    UY: 'Uruguay',
    UM: 'US Minor Outlying Islands',
    US: 'USA',
    UZ: 'Usbekistan',
    VU: 'Vanuatu',
    VA: 'Vatikanstadt',
    VE: 'Venezuela',
    AE: 'Vereinigte Arabische Emirate',
    VN: 'Vietnam',
    WF: 'Wallis und Futuna',
    CF: 'Zentralafrikanische Republik',
    CY: 'Zypern'
  },
  'es-mx': {
    AF: 'Afganistán',
    AL: 'Albania',
    DE: 'Alemania',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguila',
    AG: 'Antigua y Barbuda',
    SA: 'Arabia Saudí',
    SJ: 'Archipiélago de Svalbard',
    DZ: 'Argelia',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaiyán',
    BS: 'Bahamas',
    BH: 'Bahréin',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BE: 'Bélgica',
    BZ: 'Belice',
    BJ: 'Benín',
    BM: 'Bermudas',
    BY: 'Bielorrusia',
    MM: 'Birmania',
    BO: 'Bolivia',
    BQ: 'Bonaire, San Eustaquio y Saba',
    BA: 'Bosnia y Herzegovina',
    BW: 'Botsuana',
    BR: 'Brasil',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    BT: 'Bután',
    CV: 'Cabo Verde',
    KH: 'Camboya',
    CM: 'Camerún',
    CA: 'Canadá',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CY: 'Chipre',
    VA: 'Ciudad del Vaticano',
    CO: 'Colombia',
    KM: 'Comores',
    KP: 'Corea del Norte',
    KR: 'Corea del Sur',
    CI: 'Costa de Marfil',
    CR: 'Costa Rica',
    HR: 'Croacia',
    CU: 'Cuba',
    CW: 'Curazao',
    DK: 'Dinamarca',
    DM: 'Dominica',
    EC: 'Ecuador',
    EG: 'Egipto',
    SV: 'El Salvador',
    AE: 'Emiratos Árabes Unidos',
    ER: 'Eritrea',
    SK: 'Eslovaquia',
    SI: 'Eslovenia',
    ES: 'España',
    FM: 'Estados Federados de Micronesia',
    US: 'Estados Unidos',
    EE: 'Estonia',
    ET: 'Etiopía',
    FJ: 'Fiji',
    PH: 'Filipinas',
    FI: 'Finlandia',
    FR: 'Francia',
    GA: 'Gabón',
    GM: 'Gambia',
    GE: 'Georgia',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Granada',
    GR: 'Grecia',
    GL: 'Groenlandia',
    GP: 'Guadalupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GF: 'Guayana Francesa',
    GG: 'Guernesey',
    GN: 'Guinea',
    GQ: 'Guinea Ecuatorial',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haití',
    HN: 'Honduras',
    HU: 'Hungría',
    IN: 'India',
    ID: 'Indonesia',
    IQ: 'Irak',
    IR: 'Irán',
    IE: 'Irlanda',
    BV: 'Isla Bouvet',
    CX: 'Isla Christmas',
    IM: 'Isla de Man',
    NF: 'Isla Norfolk',
    IS: 'Islandia',
    AX: 'Islas Åland',
    KY: 'Islas Caimán',
    CC: 'Islas Cocos',
    CK: 'Islas Cook',
    FO: 'Islas Feroe',
    GS: 'Islas Georgias del Sur y Sandwich del Sur',
    HM: 'Islas Heard y McDonald',
    FK: 'Islas Malvinas',
    MP: 'Islas Marianas del Norte',
    MH: 'Islas Marshall',
    PN: 'Islas Pitcairn',
    SB: 'Islas Salomón',
    TC: 'Islas Turcas y Caicos',
    UM: 'Islas ultramarinas de Estados Unidos',
    VG: 'Islas Vírgenes Británicas',
    VI: 'Islas Vírgenes de los Estados Unidos',
    IL: 'Israel',
    IT: 'Italia',
    JM: 'Jamaica',
    JP: 'Japón',
    JE: 'Jersey',
    JO: 'Jordania',
    KZ: 'Kazajistán',
    KE: 'Kenia',
    KG: 'Kirguizistán',
    KI: 'Kiribati',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesoto',
    LV: 'Letonia',
    LB: 'Líbano',
    LR: 'Liberia',
    LY: 'Libia',
    LI: 'Liechtenstein',
    LT: 'Lituania',
    LU: 'Luxemburgo',
    MK: 'Macedonia del Norte',
    MG: 'Madagascar',
    MY: 'Malasia',
    MW: 'Malawi',
    MV: 'Maldivas',
    ML: 'Malí',
    MT: 'Malta',
    MA: 'Marruecos',
    MQ: 'Martinica',
    MU: 'Mauricio',
    MR: 'Mauritania',
    YT: 'Mayotte',
    MX: 'México',
    MD: 'Moldavia',
    MC: 'Mónaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NI: 'Nicaragua',
    NE: 'Níger',
    NG: 'Nigeria',
    NU: 'Niue',
    NO: 'Noruega',
    NC: 'Nueva Caledonia',
    NZ: 'Nueva Zelanda',
    OM: 'Omán',
    NL: 'Países Bajos',
    PK: 'Pakistán',
    PW: 'Palau',
    PA: 'Panamá',
    PG: 'Papúa-Nueva Guinea',
    PY: 'Paraguay',
    PE: 'Perú',
    PF: 'Polinesia Francesa',
    PL: 'Polonia',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    HK: 'RAE de Hong Kong',
    MO: 'RAE de Macao',
    GB: 'Reino Unido',
    CF: 'República Centroafricana',
    CZ: 'República Checa',
    CG: 'República Democrática del Congo',
    CD: 'República Democrática del Congo',
    DO: 'República Dominicana',
    SO: 'República Federal de Somalia',
    RE: 'Reunión',
    RW: 'Ruanda',
    RO: 'Rumania',
    RU: 'Rusia',
    MF: 'Saint Martin',
    WS: 'Samoa',
    AS: 'Samoa Americana',
    SM: 'San Marino',
    SX: 'San Martín',
    VC: 'San Vicente y las Granadinas',
    SH: 'Santa Elena Helena',
    LC: 'Santa Lucía',
    ST: 'Santo Tomé y Príncipe',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leona',
    SG: 'Singapur',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    KN: 'St. Kitts y Nevis',
    PM: 'St. Saint Pierre y Miquelon',
    SZ: 'Suazilandia',
    ZA: 'Sudáfrica',
    SD: 'Sudán',
    SS: 'Sudán del Sur',
    SE: 'Suecia',
    CH: 'Suiza',
    SR: 'Surinam',
    SY: 'Syria',
    TH: 'Tailandia',
    TW: 'Taiwán',
    TZ: 'Tanzania',
    TJ: 'Tayikistán',
    IO: 'Territorio Británico del Océano Índico',
    TF: 'Territorios Australes Franceses',
    PS: 'Territorios Palestinos',
    TL: 'Timor Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad y Tobago',
    TN: 'Túnez',
    TM: 'Turkmenistán',
    TR: 'Turquía',
    TV: 'Tuvalu',
    UA: 'Ucrania',
    UG: 'Uganda',
    UY: 'Uruguay',
    UZ: 'Uzbekistán',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis y Futuna',
    YE: 'Yemen',
    DJ: 'Yibuti',
    ZM: 'Zambia',
    ZW: 'Zimbabue'
  },
  'ja-jp': {
    IS: 'アイスランド',
    IE: 'アイルランド',
    AZ: 'アゼルバイジャン',
    AF: 'アフガニスタン・イスラム共和国',
    US: 'アメリカ',
    AS: 'アメリカン　サモア',
    AE: 'アラブ首長国連邦',
    DZ: 'アルジェリア',
    AR: 'アルゼンチン',
    AL: 'アルバニア',
    AW: 'アルバ島',
    AM: 'アルメニア',
    AI: 'アングィラ',
    AO: 'アンゴラ',
    AG: 'アンティグアバーブーダ',
    AD: 'アンドラ',
    YE: 'イエメン共和国',
    GB: 'イギリス',
    VG: 'イギリス領バージン諸島',
    IL: 'イスラエル',
    IT: 'イタリア',
    IQ: 'イラク共和国',
    IR: 'イラン・イスラム共和国',
    IN: 'インド',
    ID: 'インドネシア',
    WF: 'ウオリス・フツナ',
    UG: 'ウガンダ',
    UA: 'ウクライナ',
    UZ: 'ウズベキスタン',
    UY: 'ウルグアイ',
    EC: 'エクアドル',
    EG: 'エジプト',
    EE: 'エストニア',
    SZ: 'エスワティニ',
    ET: 'エチオピア',
    ER: 'エリトリア',
    SV: 'エルサルバドル',
    AU: 'オーストラリア',
    AT: 'オーストリア',
    AX: 'オーランド諸島',
    OM: 'オマーン国',
    NL: 'オランダ',
    GH: 'ガーナ',
    CV: 'カーボベルデ共和国',
    GG: 'ガーンジー',
    GY: 'ガイアナ協同共和国',
    KZ: 'カザフスタン',
    QA: 'カタール',
    CA: 'カナダ',
    GA: 'ガボン',
    CM: 'カメルーン共和国',
    GM: 'ガンビア共和国',
    KH: 'カンボジア',
    GN: 'ギニア',
    GW: 'ギニア・ビサウ共和国',
    CY: 'キプロス',
    CU: 'キューバ共和国',
    CW: 'キュラソー',
    GR: 'ギリシャ',
    KI: 'キリバス共和国',
    KG: 'キルギス共和国',
    GT: 'グアテマラ',
    GP: 'グアドループ',
    GU: 'グアム',
    KW: 'クウェート国',
    CK: 'クック諸島',
    GL: 'グリーンランド',
    CX: 'クリスマス島',
    GE: 'グルジア',
    GD: 'グレナダ',
    HR: 'クロアチア',
    KY: 'ケイマン諸島',
    KE: 'ケニア',
    CI: 'コートジボワール',
    CC: 'ココス諸島',
    CR: 'コスタリカ共和国',
    KM: 'コモロ連合',
    CO: 'コロンビア',
    CG: 'コンゴ共和国',
    CD: 'コンゴ民主共和国',
    SA: 'サウジアラビア',
    GS: 'サウスジョージアおよびサウスサンドウィッチ諸島',
    WS: 'サモア',
    SM: 'サン・マリノ共和国',
    ST: 'サントメ プリンシペ',
    BL: 'サンバルテルミ',
    ZM: 'ザンビア',
    PM: 'サンピエール・ミクロン島',
    SL: 'シエラレオネ',
    DJ: 'ジブチ',
    GI: 'ジブラルタル',
    JE: 'ジャージー',
    JM: 'ジャマイカ',
    SY: 'シリア',
    SG: 'シンガポール',
    SX: 'シント マールテン',
    ZW: 'ジンバブエ',
    CH: 'スイス',
    SE: 'スウェーデン',
    SD: 'スーダン共和国',
    SJ: 'スバールバル諸島',
    ES: 'スペイン',
    SR: 'スリナム',
    LK: 'スリランカ',
    SK: 'スロバキア',
    SI: 'スロベニア',
    SC: 'セーシェル',
    SN: 'セネガル',
    RS: 'セルビア',
    VC: 'セント ビンセントおよびグレナディン諸島',
    SH: 'セント ヘレナ',
    KN: 'セントクリストファー ネーヴィス',
    MF: 'セントマーチン島',
    LC: 'セントルシア',
    SO: 'ソマリア連邦共和国',
    SB: 'ソロモン諸島',
    TC: 'タークスカイコス諸島',
    TH: 'タイ',
    TJ: 'タジキスタン共和国',
    TZ: 'タンザニア',
    CZ: 'チェコ',
    TD: 'チャド',
    TN: 'チュニジア',
    CL: 'チリ',
    TV: 'ツバル',
    TL: 'ティモール レスト',
    DK: 'デンマーク',
    DE: 'ドイツ',
    TG: 'トーゴ',
    TK: 'トケラウ諸島',
    DO: 'ドミニカ共和国',
    DM: 'ドミニカ国',
    TT: 'トリニダード トバゴ',
    TM: 'トルクメニスタン',
    TR: 'トルコ',
    TO: 'トンガ',
    NG: 'ナイジェリア',
    NR: 'ナウル共和国',
    NA: 'ナミビア',
    NU: 'ニウエ',
    NI: 'ニカラグア',
    NE: 'ニジェール',
    NC: 'ニュー・カレドニア島',
    NZ: 'ニュージーランド',
    NP: 'ネパール',
    NF: 'ノーフォーク島',
    NO: 'ノルウェー',
    HM: 'ハード、マクドナルド島',
    BH: 'バーレーン',
    HT: 'ハイチ',
    PK: 'パキスタン',
    VA: 'バチカン市国',
    PA: 'パナマ共和国',
    VU: 'バヌアツ共和国',
    BS: 'バハマ',
    PG: 'パプアニューギニア',
    BM: 'バミューダ',
    PW: 'パラオ共和国',
    PY: 'パラグアイ共和国',
    BB: 'バルバドス',
    PS: 'パレスチナ自治区',
    HU: 'ハンガリー',
    BD: 'バングラデシュ',
    PN: 'ピトケアン諸島',
    FJ: 'フィジー',
    PH: 'フィリピン',
    FI: 'フィンランド',
    BT: 'ブータン王国',
    BV: 'ブーべ島',
    PR: 'プエルトリコ',
    FO: 'フェロー諸島',
    FK: 'フォークランド諸島',
    BR: 'ブラジル',
    FR: 'フランス',
    GF: 'フランス領ギアナ',
    PF: 'フランス領ポリネシア',
    TF: 'フランス領極南諸島',
    BG: 'ブルガリア',
    BF: 'ブルキナファソ',
    BN: 'ブルネイ・ダルサラーム国',
    BI: 'ブルンジ',
    VN: 'ベトナム',
    BJ: 'ベニン共和国',
    VE: 'ベネズエラ',
    BY: 'ベラルーシ',
    BZ: 'ベリーズ',
    PE: 'ペルー',
    BE: 'ベルギー',
    PL: 'ポーランド',
    BA: 'ボスニア・ヘルツェゴビナ',
    BW: 'ボツワナ共和国',
    BQ: 'ボネール, セント ユースタティウスとサバ',
    BO: 'ボリビア',
    PT: 'ポルトガル',
    HN: 'ホンジュラス',
    MH: 'マーシャル諸島共和国',
    MO: 'マカオ特別行政区',
    MG: 'マダガスカル',
    YT: 'マヨット',
    MW: 'マラウイ',
    ML: 'マリ',
    MT: 'マルタ',
    MQ: 'マルティニーク島',
    MY: 'マレーシア',
    IM: 'マン島',
    FM: 'ミクロネシア連邦',
    MM: 'ミャンマー',
    MX: 'メキシコ',
    MU: 'モーリシャス共和国',
    MR: 'モーリタニア',
    MZ: 'モザンビーク',
    MC: 'モナコ',
    MV: 'モルディブ',
    MD: 'モルドバ',
    MA: 'モロッコ',
    MN: 'モンゴル',
    ME: 'モンテネグロ',
    MS: 'モントセラト島',
    JO: 'ヨルダン',
    LA: 'ラオス',
    LV: 'ラトビア',
    LT: 'リトアニア',
    LY: 'リビア・アラブ国',
    LI: 'リヒテンシュタイン公国',
    LR: 'リベリア共和国',
    RO: 'ルーマニア',
    LU: 'ルクセンブルク大公国',
    RW: 'ルワンダ',
    LS: 'レソト',
    LB: 'レバノン',
    RE: 'レユニオン',
    RU: 'ロシア',
    IO: '英領インド洋地域',
    KR: '韓国',
    HK: '香港特別行政区',
    UM: '合衆国領有小離島',
    GQ: '赤道ギニア',
    TW: '台湾',
    CF: '中央アフリカ共和国',
    CN: '中国',
    KP: '朝鮮民主主義人民共和国',
    ZA: '南アフリカ',
    SS: '南スーダン',
    JP: '日本',
    VI: '米領バージン諸島',
    MK: '北マケドニア',
    MP: '北マリアナ諸島'
  },
  'ko-kr': {
    GH: '가나',
    GA: '가봉',
    GY: '가이아나',
    GM: '감비아',
    GG: '건지',
    GP: '과들루프',
    GT: '과테말라',
    GU: '괌',
    GD: '그레나다',
    GR: '그리스',
    GL: '그린란드',
    GN: '기니',
    GW: '기니비사우',
    NA: '나미비아',
    NR: '나우루',
    NG: '나이지리아',
    SS: '남수단',
    ZA: '남아프리카공화국',
    NL: '네덜란드',
    NP: '네팔',
    NO: '노르웨이',
    NF: '노퍽 섬',
    NZ: '뉴질랜드',
    NC: '뉴칼레도니아',
    NU: '니우에',
    NE: '니제르',
    NI: '니카라과',
    TW: '대만',
    DK: '덴마크',
    DM: '도미니카',
    DO: '도미니카공화국',
    DE: '독일',
    TL: '동티모르',
    LA: '라오스',
    LR: '라이베리아',
    LV: '라트비아',
    RU: '러시아',
    LB: '레바논',
    LS: '레소토',
    RE: '레위니옹',
    RO: '루마니아',
    LU: '룩셈부르크',
    RW: '르완다',
    LY: '리비아',
    LT: '리투아니아',
    LI: '리히텐슈타인',
    MG: '마다가스카르',
    MQ: '마르티니크',
    MH: '마셜 제도',
    YT: '마요트',
    MO: '마카오 특별행정구',
    MW: '말라위',
    MY: '말레이시아',
    ML: '말리',
    IM: '맨 섬',
    MX: '멕시코',
    MC: '모나코',
    MA: '모로코',
    MU: '모리셔스',
    MR: '모리타니',
    MZ: '모잠비크',
    MS: '몬세라트',
    ME: '몬테네그로',
    MD: '몰도바',
    MV: '몰디브',
    MT: '몰타',
    MN: '몽골',
    US: '미국',
    UM: '미국령 군소 제도',
    VI: '미국령 버진아일랜드',
    AS: '미국령 사모아',
    MM: '미얀마',
    FM: '미크로네시아 연방',
    VU: '바누아투',
    BH: '바레인',
    BB: '바베이도스',
    VA: '바티칸 시티',
    BS: '바하마',
    BD: '방글라데시',
    BM: '버뮤다',
    BJ: '베냉',
    VE: '베네수엘라',
    VN: '베트남',
    BE: '벨기에',
    BY: '벨라루스',
    BZ: '벨리즈',
    BQ: '보네르, 신트유스타티우스 및 사바',
    BA: '보스니아 헤르체고비나',
    BW: '보츠와나',
    BO: '볼리비아',
    BI: '부룬디',
    BF: '부르키나파소',
    BV: '부베 섬',
    BT: '부탄',
    MP: '북마리아나제도',
    MK: '북마케도니아',
    KP: '북한',
    BG: '불가리아',
    BR: '브라질',
    BN: '브루나이',
    WS: '사모아',
    SA: '사우디아라비아',
    GS: '사우스조지아 사우스샌드위치 제도',
    SM: '산마리노',
    ST: '상투메 프린시페',
    MF: '생마르탱',
    BL: '생바르텔레미',
    PM: '생피에르미클롱',
    SN: '세네갈',
    RS: '세르비아',
    SC: '세이셸',
    LC: '세인트루시아',
    VC: '세인트빈센트그레나딘',
    KN: '세인트키츠네비스',
    SH: '세인트헬레나',
    SO: '소말리아',
    SB: '솔로몬 제도',
    SD: '수단',
    SR: '수리남',
    LK: '스리랑카',
    SJ: '스발바르',
    SE: '스웨덴',
    CH: '스위스',
    ES: '스페인',
    SK: '슬로바키아',
    SI: '슬로베니아',
    SY: '시리아',
    SL: '시에라리온',
    SX: '신트마르턴',
    SG: '싱가포르',
    AE: '아랍에미리트',
    AW: '아루바',
    AM: '아르메니아',
    AR: '아르헨티나',
    IS: '아이슬란드',
    HT: '아이티',
    IE: '아일랜드',
    AZ: '아제르바이잔',
    AF: '아프가니스탄',
    AD: '안도라',
    AL: '알바니아',
    DZ: '알제리',
    AO: '앙골라',
    AG: '앤티카바부다',
    AI: '앵귈라',
    ER: '에리트레아',
    SZ: '에스와티니',
    EE: '에스토니아',
    EC: '에콰도르',
    ET: '에티오피아',
    SV: '엘살바도르',
    GB: '영국',
    VG: '영국령 버진아일랜드',
    IO: '영국령 인도양 식민지',
    YE: '예멘',
    OM: '오만',
    AT: '오스트리아',
    HN: '온두라스',
    AX: '올란드 제도',
    JO: '요르단',
    UG: '우간다',
    UY: '우루과이',
    UZ: '우즈베키스탄',
    UA: '우크라이나',
    WF: '월리스푸투나',
    IQ: '이라크',
    IR: '이란',
    IL: '이스라엘',
    EG: '이집트',
    IT: '이탈리아',
    IN: '인도',
    ID: '인도네시아',
    JP: '일본',
    JM: '자메이카',
    ZM: '잠비아',
    JE: '저지',
    GQ: '적도 기니',
    GE: '조지아',
    CN: '중국',
    CF: '중앙아프리카공화국',
    DJ: '지부티',
    GI: '지브롤터',
    ZW: '짐바브웨',
    TD: '차드',
    CZ: '체코',
    CL: '칠레',
    CM: '카메룬',
    CV: '카보베르데',
    KZ: '카자흐스탄',
    QA: '카타르',
    KH: '캄보디아',
    CA: '캐나다',
    KE: '케냐',
    KY: '케이맨 제도',
    KM: '코모로',
    CR: '코스타리카',
    CC: '코코스 제도',
    CI: '코트디부아르',
    CO: '콜롬비아',
    CG: '콩고',
    CD: '콩고민주공화국',
    CU: '쿠바',
    KW: '쿠웨이트',
    CK: '쿡 제도',
    CW: '퀴라소',
    HR: '크로아티아',
    CX: '크리스마스 섬',
    KG: '키르기스스탄',
    KI: '키리바시',
    CY: '키프로스',
    TJ: '타지키스탄',
    TZ: '탄자니아',
    TH: '태국',
    TC: '터크스케이커스',
    TG: '토고',
    TK: '토켈라우',
    TO: '통가',
    TM: '투르크메니스탄',
    TV: '투발루',
    TN: '튀니지',
    TR: '튀르키예',
    TT: '트리니다드 토바고',
    PA: '파나마',
    PY: '파라과이',
    PK: '파키스탄',
    PG: '파푸아뉴기니',
    PW: '팔라우',
    PS: '팔레스타인 영토',
    FO: '페로 제도',
    PE: '페루',
    PT: '포르투갈',
    FK: '포클랜드 제도',
    PL: '폴란드',
    PR: '푸에르토리코',
    FR: '프랑스',
    GF: '프랑스령 기아나',
    TF: '프랑스령 남부와 남극 지역',
    PF: '프랑스령 폴리네시아',
    FJ: '피지',
    PN: '피트케언 제도',
    FI: '핀란드',
    PH: '필리핀',
    KR: '한국',
    HM: '허드 섬 및 맥도널드 제도',
    HU: '헝가리',
    AU: '호주',
    HK: '홍콩 특별행정구'
  },
  'zh-tw': {
    TT: '千里達及托巴哥',
    TR: '土耳其',
    TC: '土克凱可群島',
    TM: '土庫曼',
    BT: '不丹',
    CF: '中非共和國',
    CN: '中國',
    DK: '丹麥',
    EC: '厄瓜多',
    ER: '厄利垂亞',
    PG: '巴布亞紐幾內亞',
    BR: '巴西',
    BB: '巴貝多',
    PY: '巴拉圭',
    BH: '巴林',
    BS: '巴哈馬',
    PA: '巴拿馬',
    PS: '巴勒斯坦領土',
    PK: '巴基斯坦',
    JP: '日本',
    BE: '比利時',
    JM: '牙買加',
    IL: '以色列',
    CA: '加拿大',
    GA: '加彭',
    MP: '北馬里亞納群島',
    MK: '北馬其頓',
    KP: '北韓',
    QA: '卡達',
    CU: '古巴',
    CW: '古拉索',
    TW: '台灣',
    SZ: '史瓦帝尼',
    NE: '尼日',
    NI: '尼加拉瓜',
    NP: '尼泊爾',
    BF: '布吉納法索',
    BV: '布威島',
    GT: '瓜地馬拉',
    GP: '瓜德羅普',
    WF: '瓦利斯和富圖那',
    GM: '甘比亞',
    BY: '白俄羅斯',
    PN: '皮特肯群島',
    LT: '立陶宛',
    IQ: '伊拉克',
    IR: '伊朗',
    IS: '冰島',
    LI: '列支敦斯登',
    HU: '匈牙利',
    ID: '印尼',
    IN: '印度',
    DJ: '吉布地',
    KI: '吉里巴斯',
    KG: '吉爾吉斯',
    TV: '吐瓦魯',
    DM: '多米尼克',
    DO: '多明尼加共和國',
    TG: '多哥',
    AI: '安圭拉',
    AG: '安地卡及巴布達',
    AO: '安哥拉',
    AD: '安道爾',
    TK: '托克勞',
    BM: '百慕達',
    ET: '衣索比亞',
    ES: '西班牙',
    HR: '克羅埃西亞',
    LY: '利比亞',
    HN: '宏都拉斯',
    GR: '希臘',
    BN: '汶萊',
    SA: '沙烏地阿拉伯',
    BZ: '貝里斯',
    BJ: '貝南',
    GQ: '赤道幾內亞',
    ZW: '辛巴威',
    AM: '亞美尼亞',
    AZ: '亞塞拜然',
    TZ: '坦尚尼亞',
    NG: '奈及利亞',
    VE: '委內瑞拉',
    BD: '孟加拉',
    ZM: '尚比亞',
    PW: '帛琉',
    LV: '拉脫維亞',
    TO: '東加',
    TL: '東帝汶',
    FR: '法國',
    TF: '法國南半球及南極屬地',
    FO: '法羅群島',
    GF: '法屬圭亞那',
    PF: '法屬玻里尼西亞',
    MF: '法屬聖馬丁',
    BA: '波士尼亞與赫塞哥維納',
    BW: '波札那',
    PR: '波多黎各',
    PL: '波蘭',
    GI: '直布羅陀',
    KE: '肯亞',
    FI: '芬蘭',
    AE: '阿拉伯聯合大公國',
    AR: '阿根廷',
    OM: '阿曼',
    AF: '阿富汗',
    DZ: '阿爾及利亞',
    AL: '阿爾巴尼亞',
    AW: '阿魯巴',
    RU: '俄羅斯',
    BG: '保加利亞',
    ZA: '南非',
    GS: '南喬治亞與南桑威奇群島',
    SS: '南蘇丹',
    KZ: '哈薩克',
    TD: '查德',
    KH: '柬埔寨',
    BO: '玻利維亞',
    KW: '科威特',
    CC: '科科斯群島',
    TN: '突尼西亞',
    JO: '約旦',
    US: '美國',
    UM: '美國本土外小島',
    VI: '美屬維爾京群島',
    AS: '美屬薩摩亞',
    GB: '英國',
    IO: '英屬印度洋領地',
    VG: '英屬維京群島',
    MR: '茅利塔尼亞',
    GH: '迦納',
    HK: '香港特別行政區',
    CD: '剛果民主共和國',
    CG: '剛果共和國',
    CO: '哥倫比亞',
    CR: '哥斯大黎加',
    EG: '埃及',
    CK: '庫克群島',
    NO: '挪威',
    GG: '根西',
    GL: '格陵蘭',
    GD: '格瑞納達',
    TH: '泰國',
    HT: '海地',
    UG: '烏干達',
    UA: '烏克蘭',
    UY: '烏拉圭',
    UZ: '烏茲別克',
    RE: '留尼旺',
    PE: '秘魯',
    NA: '納米比亞',
    NZ: '紐西蘭',
    NU: '紐埃',
    SO: '索馬利亞聯邦共和國',
    SB: '索羅門群島',
    MQ: '馬丁尼克',
    ML: '馬利',
    MY: '馬來西亞',
    MW: '馬拉威',
    YT: '馬約特',
    MH: '馬紹爾群島',
    MG: '馬達加斯加',
    MT: '馬爾他',
    MV: '馬爾地夫',
    FM: '密克羅尼西亞聯邦',
    CZ: '捷克',
    SY: '敘利亞',
    IM: '曼島',
    VA: '梵蒂岡',
    SX: '荷屬聖馬丁',
    NL: '荷蘭',
    BQ: '荷蘭加勒比區',
    MZ: '莫三比克',
    CM: '喀麥隆',
    GE: '喬治亞',
    GN: '幾內亞',
    GW: '幾內亞比索',
    FJ: '斐濟',
    SJ: '斯瓦巴',
    LK: '斯里蘭卡',
    SK: '斯洛伐克',
    SI: '斯洛維尼亞',
    CL: '智利',
    PH: '菲律賓',
    CI: '象牙海岸',
    VN: '越南',
    KY: '開曼群島',
    TJ: '塔吉克',
    SN: '塞內加爾',
    SC: '塞席爾',
    RS: '塞爾維亞',
    AT: '奧地利',
    AX: '奧蘭群島',
    EE: '愛沙尼亞',
    IE: '愛爾蘭',
    SG: '新加坡',
    NC: '新喀里多尼亞',
    SL: '獅子山',
    CH: '瑞士',
    SE: '瑞典',
    VU: '萬那杜',
    IT: '義大利',
    BL: '聖巴瑟米',
    VC: '聖文森及格瑞那丁',
    PM: '聖皮耶及密克隆群島',
    ST: '聖多美普林西比',
    KN: '聖克里斯多福及尼維斯',
    SM: '聖馬利諾',
    SH: '聖赫勒拿',
    CX: '聖誕島',
    LC: '聖露西亞',
    YE: '葉門',
    KM: '葛摩',
    PT: '葡萄牙',
    FK: '福克蘭群島',
    CV: '維德角',
    MN: '蒙古',
    MS: '蒙哲臘',
    ME: '蒙特內哥羅',
    BI: '蒲隆地',
    GY: '蓋亞那',
    HM: '赫德島及麥當勞群島',
    LA: '寮國',
    DE: '德國',
    MA: '摩洛哥',
    MC: '摩納哥',
    MD: '摩爾多瓦',
    MU: '模里西斯',
    MM: '緬甸',
    LB: '黎巴嫩',
    MX: '墨西哥',
    JE: '澤西島',
    MO: '澳門特別行政區',
    AU: '澳洲',
    RW: '盧安達',
    LU: '盧森堡',
    NF: '諾福克島',
    NR: '諾魯',
    LR: '賴比瑞亞',
    LS: '賴索托',
    CY: '賽普勒斯',
    KR: '韓國',
    SV: '薩爾瓦多',
    WS: '薩摩亞',
    RO: '羅馬尼亞',
    GU: '關島',
    SD: '蘇丹',
    SR: '蘇利南'
  },
  'zh-hk': {
    YE: '也門',
    TT: '千里達及多巴哥',
    TR: '土耳其',
    TM: '土庫曼',
    BT: '不丹',
    CF: '中非共和國',
    CN: '中國',
    DK: '丹麥',
    EC: '厄瓜多爾',
    ER: '厄立特里亞',
    BB: '巴巴多斯',
    PG: '巴布亞新畿內亞',
    BR: '巴西',
    PY: '巴拉圭',
    BH: '巴林',
    BS: '巴哈馬',
    PA: '巴拿馬',
    PS: '巴勒斯坦領土',
    PK: '巴基斯坦',
    JP: '日本',
    BE: '比利時',
    MR: '毛里塔尼亞',
    MU: '毛里裘斯',
    JM: '牙買加',
    IL: '以色列',
    CA: '加拿大',
    GH: '加納',
    GA: '加蓬',
    MP: '北馬里亞納群島',
    MK: '北馬其頓',
    KP: '北韓',
    QA: '卡塔爾',
    CU: '古巴',
    TW: '台灣',
    SZ: '史瓦帝尼',
    NG: '尼日利亞',
    NE: '尼日爾',
    NI: '尼加拉瓜',
    NP: '尼泊爾',
    BF: '布吉納法索',
    BI: '布隆迪',
    GP: '瓜德羅普',
    WF: '瓦利斯和富圖那',
    VU: '瓦努阿圖',
    GM: '甘比亞',
    BY: '白俄羅斯',
    PN: '皮特肯群島',
    LT: '立陶宛',
    IQ: '伊拉克',
    IR: '伊朗',
    IS: '冰島',
    LI: '列支敦士登',
    HU: '匈牙利',
    ID: '印尼',
    IN: '印度',
    GT: '危地馬拉',
    DJ: '吉布提',
    KG: '吉爾吉斯',
    GY: '圭亞那',
    DM: '多米尼克',
    DO: '多明尼加共和國',
    TG: '多哥',
    AI: '安圭拉',
    AO: '安哥拉',
    AG: '安提瓜和巴布達',
    AD: '安道爾',
    TK: '托克勞',
    BM: '百慕達',
    LA: '老撾',
    ES: '西班牙',
    BZ: '伯利茲',
    HR: '克羅地亞',
    SJ: '冷岸群島',
    LR: '利比里亞',
    LY: '利比亞',
    GR: '希臘',
    BN: '汶萊',
    SA: '沙特阿拉伯',
    BJ: '貝寧',
    GQ: '赤道畿內亞',
    AM: '亞美尼亞',
    TZ: '坦桑尼亞',
    VE: '委內瑞拉',
    BD: '孟加拉',
    PW: '帛琉',
    SB: '所羅門群島',
    LV: '拉脫維亞',
    TO: '東加',
    TL: '東帝汶',
    FR: '法國',
    TF: '法國南半球及南極屬地',
    FO: '法羅群島',
    GF: '法屬圭亞那',
    PF: '法屬玻里尼西亞',
    PR: '波多黎各',
    BA: '波斯尼亞和黑塞哥維那',
    PL: '波蘭',
    GI: '直布羅陀',
    KE: '肯亞',
    FI: '芬蘭',
    AE: '阿拉伯聯合酋長國',
    AR: '阿根廷',
    OM: '阿曼',
    AF: '阿富汗',
    AZ: '阿塞拜疆',
    DZ: '阿爾及利亞',
    AL: '阿爾巴尼亞',
    AW: '阿魯巴',
    RU: '俄羅斯',
    BG: '保加利亞',
    GS: '南佐治亞及南桑威奇群島',
    ZA: '南非',
    SS: '南蘇丹',
    KZ: '哈薩克',
    TD: '查德',
    KH: '柬埔寨',
    ZW: '津巴布韋',
    HN: '洪都拉斯',
    BO: '玻利維亞',
    KW: '科威特',
    CC: '科科斯群島',
    CI: '科特迪瓦',
    KM: '科摩羅',
    TN: '突尼西亞',
    JO: '約旦',
    US: '美國',
    UM: '美國本土外小島嶼',
    VI: '美屬維爾京群島',
    AS: '美屬薩摩亞',
    GB: '英國',
    IO: '英屬印度洋領地',
    VG: '英屬維京群島',
    HK: '香港特別行政區',
    CD: '剛果民主共和國',
    CG: '剛果共和國',
    CO: '哥倫比亞',
    CR: '哥斯達黎加',
    EG: '埃及',
    ET: '埃塞俄比亞',
    CK: '庫克群島',
    CW: '庫拉索',
    NO: '挪威',
    GG: '根西',
    GL: '格陵蘭',
    GD: '格瑞納達',
    GE: '格魯吉亞',
    TH: '泰國',
    HT: '海地',
    UG: '烏干達',
    UA: '烏克蘭',
    UY: '烏拉圭',
    UZ: '烏茲別克',
    TC: '特克斯和凱斯群島',
    RE: '留尼旺',
    PE: '秘魯',
    NA: '納米比亞',
    NZ: '紐西蘭',
    NU: '紐埃',
    SO: '索馬里聯邦共和國',
    ML: '馬里',
    MY: '馬來西亞',
    MW: '馬拉維',
    YT: '馬約特',
    MH: '馬紹爾群島',
    MQ: '馬提尼克',
    MG: '馬達加斯加',
    MT: '馬爾他',
    MV: '馬爾代夫',
    KI: '基里巴斯',
    FM: '密克羅尼西亞聯邦',
    CZ: '捷克',
    SY: '敘利亞',
    IM: '曼島',
    VA: '梵蒂岡',
    SX: '荷屬聖馬丁',
    NL: '荷蘭',
    BQ: '荷蘭加勒比區',
    MZ: '莫桑比克',
    BW: '博茨瓦納',
    CM: '喀麥隆',
    FJ: '斐濟',
    LK: '斯里蘭卡',
    SI: '斯洛文尼亞',
    SK: '斯洛伐克',
    CL: '智利',
    PH: '菲律賓',
    LS: '萊索托',
    VN: '越南',
    KY: '開曼群島',
    ME: '黑山',
    TJ: '塔吉克斯坦',
    SN: '塞內加爾',
    SC: '塞舌爾',
    SL: '塞拉利昂',
    CY: '塞浦路斯',
    RS: '塞爾維亞',
    AT: '奧地利',
    AX: '奧蘭島',
    IT: '意大利',
    EE: '愛沙尼亞',
    IE: '愛爾蘭',
    SG: '新加坡',
    NC: '新喀里多尼亞',
    NR: '瑙魯',
    CH: '瑞士',
    SE: '瑞典',
    BL: '聖巴泰勒米',
    VC: '聖文森及格瑞那丁',
    PM: '聖皮耶及密克隆群島',
    ST: '聖多美普林西比',
    KN: '聖克里斯多福及尼維斯',
    MF: '聖馬丁島',
    SM: '聖馬利諾',
    SH: '聖赫勒拿',
    CX: '聖誕島',
    LC: '聖露西亞',
    PT: '葡萄牙',
    TV: '圖瓦盧',
    FK: '福克蘭群島',
    CV: '維德角',
    MN: '蒙古',
    MS: '蒙塞拉特',
    HM: '赫德島及麥當勞群島',
    DE: '德國',
    MA: '摩洛哥',
    MC: '摩納哥',
    MD: '摩爾多瓦',
    GN: '畿內亞',
    GW: '畿內亞比索',
    MM: '緬甸',
    LB: '黎巴嫩',
    MX: '墨西哥',
    JE: '澤西',
    MO: '澳門特別行政區',
    AU: '澳洲',
    RW: '盧旺達',
    LU: '盧森堡',
    NF: '諾福克島',
    BV: '鮑威特島',
    KR: '韓國',
    SV: '薩爾瓦多',
    WS: '薩摩亞',
    RO: '羅馬尼亞',
    ZM: '贊比亞',
    GU: '關島',
    SD: '蘇丹',
    SR: '蘇利南'
  },
  'zh-cn': {
    AL: '阿尔巴尼亚',
    DZ: '阿尔及利亚',
    AF: '阿富汗',
    AR: '阿根廷',
    AE: '阿拉伯联合酋长国',
    AW: '阿鲁巴',
    OM: '阿曼',
    AZ: '阿塞拜疆',
    EG: '埃及',
    ET: '埃塞俄比亚',
    IE: '爱尔兰',
    EE: '爱沙尼亚',
    AD: '安道尔',
    AO: '安哥拉',
    AI: '安圭拉',
    AG: '安提瓜和巴布达',
    AT: '奥地利',
    AX: '奥兰群岛',
    AU: '澳大利亚',
    MO: '澳门特别行政区',
    BB: '巴巴多斯',
    PG: '巴布亚新几内亚',
    BS: '巴哈马',
    PK: '巴基斯坦',
    PY: '巴拉圭',
    PS: '巴勒斯坦领土',
    BH: '巴林',
    PA: '巴拿马',
    BR: '巴西',
    BY: '白俄罗斯',
    BM: '百慕大',
    BG: '保加利亚',
    MP: '北马里亚纳群岛',
    MK: '北马其顿',
    BJ: '贝宁',
    BE: '比利时',
    IS: '冰岛',
    PR: '波多黎各',
    PL: '波兰',
    BA: '波士尼亚与赫塞哥维纳',
    BO: '玻利维亚',
    BZ: '伯利兹',
    BW: '博茨瓦纳',
    BT: '不丹',
    BF: '布基纳法索',
    BI: '布隆迪',
    BV: '布威岛',
    KP: '朝鲜',
    GQ: '赤道几内亚',
    DK: '丹麦',
    DE: '德国',
    TL: '东帝汶',
    TG: '多哥',
    DO: '多米尼加共和国',
    DM: '多米尼克',
    RU: '俄罗斯',
    EC: '厄瓜多尔',
    ER: '厄立特里亚',
    FR: '法国',
    FO: '法罗群岛',
    PF: '法属波利尼西亚',
    GF: '法属圭亚那',
    TF: '法属南方和南极领地',
    MF: '法属圣马丁',
    VA: '梵蒂冈城',
    PH: '菲律宾',
    FJ: '斐济',
    FI: '芬兰',
    CV: '佛得角',
    FK: '福克兰群岛',
    GM: '冈比亚',
    CG: '刚果共和国',
    CD: '刚果民主共和国',
    CO: '哥伦比亚',
    CR: '哥斯达黎加',
    GL: '格陵兰',
    GE: '格鲁吉亚',
    GD: '格瑞纳达',
    GG: '根西',
    CU: '古巴',
    CW: '古拉索',
    GP: '瓜德罗普',
    GU: '关岛',
    GY: '圭亚那',
    KZ: '哈萨克斯坦',
    HT: '海地',
    KR: '韩国',
    NL: '荷兰',
    BQ: '荷兰加勒比区',
    SX: '荷属圣马丁',
    HM: '赫德岛和麦克唐纳群岛',
    HN: '洪都拉斯',
    KI: '基里巴斯',
    DJ: '吉布提',
    KG: '吉尔吉斯斯坦',
    GN: '几内亚',
    GW: '几内亚比绍',
    CA: '加拿大',
    GH: '加纳',
    GA: '加蓬',
    KH: '柬埔寨',
    CZ: '捷克',
    ZW: '津巴布韦',
    CM: '喀麦隆',
    QA: '卡塔尔',
    KY: '开曼群岛',
    CC: '科科斯群岛',
    KM: '科摩罗',
    CI: '科特迪瓦',
    KW: '科威特',
    HR: '克罗地亚',
    KE: '肯尼亚',
    CK: '库克群岛',
    LV: '拉脱维亚',
    LS: '莱索托',
    LA: '老挝',
    LB: '黎巴嫩',
    LT: '立陶宛',
    LR: '利比里亚',
    LY: '利比亚',
    LI: '列支敦士登',
    RE: '留尼旺岛',
    LU: '卢森堡',
    RW: '卢旺达',
    RO: '罗马尼亚',
    MG: '马达加斯加',
    IM: '马恩岛',
    MV: '马尔代夫',
    MT: '马耳他',
    MW: '马拉维',
    MY: '马来西亚',
    ML: '马里',
    MH: '马绍尔群岛',
    MQ: '马提尼克',
    YT: '马约特',
    MU: '毛里求斯',
    MR: '毛里塔尼亚',
    US: '美国',
    UM: '美国本土外小岛屿',
    AS: '美属萨摩亚',
    VI: '美属维尔京群岛',
    MN: '蒙古',
    ME: '蒙特内哥罗',
    MS: '蒙哲腊',
    BD: '孟加拉国',
    PE: '秘鲁',
    FM: '密克罗尼西亚联邦',
    MM: '缅甸',
    MD: '摩尔多瓦',
    MA: '摩洛哥',
    MC: '摩纳哥',
    MZ: '莫桑比克',
    MX: '墨西哥',
    NA: '纳米比亚',
    ZA: '南非',
    GS: '南乔治亚与南桑威奇群岛',
    SS: '南苏丹',
    NR: '瑙鲁',
    NI: '尼加拉瓜',
    NP: '尼泊尔',
    NE: '尼日尔',
    NG: '尼日利亚',
    NU: '纽埃',
    NO: '挪威',
    NF: '诺福克岛',
    PW: '帕劳',
    PN: '皮特肯群島',
    PT: '葡萄牙',
    JP: '日本',
    SE: '瑞典',
    CH: '瑞士',
    SV: '萨尔瓦多',
    WS: '萨摩亚',
    RS: '塞尔维亚',
    SL: '塞拉利昂',
    SN: '塞内加尔',
    CY: '塞浦路斯',
    SC: '塞舌尔',
    SA: '沙特阿拉伯',
    BL: '圣巴泰勒米',
    CX: '圣诞岛',
    SH: '圣赫勒拿',
    KN: '圣基茨和尼维斯',
    LC: '圣卢西亚',
    SM: '圣马利诺',
    PM: '圣皮埃尔和密克隆',
    VC: '圣文森特和格林纳丁斯',
    ST: '聖多美普林西比',
    SZ: '史瓦帝尼',
    LK: '斯里兰卡',
    SK: '斯洛伐克',
    SI: '斯洛文尼亚',
    SJ: '斯瓦巴',
    SD: '苏丹',
    SR: '苏里南',
    SB: '所罗门群岛',
    SO: '索马里联邦共和国',
    TJ: '塔吉克斯坦',
    TW: '台湾',
    TH: '泰国',
    TZ: '坦桑尼亚',
    TO: '汤加',
    TT: '特立尼达和多巴哥',
    TN: '突尼斯',
    TV: '图瓦卢',
    TR: '土耳其',
    TC: '土克凯可群岛',
    TM: '土库曼斯坦',
    TK: '托克劳',
    WF: '瓦利斯和富图那',
    VU: '瓦努阿图',
    GT: '危地马拉',
    VE: '委内瑞拉',
    BN: '文莱',
    UG: '乌干达',
    UA: '乌克兰',
    UY: '乌拉圭',
    UZ: '乌兹别克斯坦',
    ES: '西班牙',
    GR: '希腊',
    HK: '香港特别行政区',
    SG: '新加坡',
    NC: '新喀里多尼亚',
    NZ: '新西兰',
    HU: '匈牙利',
    SY: '叙利亚',
    JM: '牙买加',
    AM: '亚美尼亚',
    YE: '也门',
    IQ: '伊拉克',
    IR: '伊朗',
    IL: '以色列',
    IT: '意大利',
    IN: '印度',
    ID: '印度尼西亚',
    GB: '英国',
    VG: '英属维京群岛',
    IO: '英属印度洋领地',
    JO: '约旦',
    VN: '越南',
    ZM: '赞比亚',
    JE: '泽西',
    TD: '乍得',
    GI: '直布罗陀',
    CL: '智利',
    CF: '中非共和国',
    CN: '中国'
  },
  'pt-br': {
    AF: 'Afeganistão',
    ZA: 'África do Sul',
    AL: 'Albânia',
    DE: 'Alemanha',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antígua e Barbuda',
    SA: 'Arábia Saudita',
    DZ: 'Argélia',
    AR: 'Argentina',
    AM: 'Armênia',
    AW: 'Aruba',
    AU: 'Austrália',
    AT: 'Áustria',
    AZ: 'Azerbaijão',
    BS: 'Bahamas',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BH: 'Barein',
    BY: 'Belarus',
    BE: 'Bélgica',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermudas',
    BO: 'Bolívia',
    BQ: 'Bonaire',
    BA: 'Bósnia-Herzegovina',
    BW: 'Botsuana',
    BR: 'Brasil',
    BN: 'Brunei',
    BG: 'Bulgária',
    BF: 'Burkina Fasso',
    MM: 'Burma',
    BI: 'Burundi',
    BT: 'Butão',
    CV: 'Cabo Verde',
    CM: 'Camarões',
    KH: 'Camboja',
    CA: 'Canadá',
    QA: 'Catar',
    KZ: 'Cazaquistão',
    TD: 'Chade',
    CL: 'Chile',
    CN: 'China',
    CY: 'Chipre',
    VA: 'Cidade do Vaticano',
    SG: 'Cingapura',
    CO: 'Colômbia',
    KP: 'Coreia do Norte',
    KR: 'Coreia do Sul',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croácia',
    CU: 'Cuba',
    CW: 'Curaçao',
    DK: 'Dinamarca',
    DJ: 'Djibuti',
    DM: 'Dominica',
    EG: 'Egito',
    SV: 'El Salvador',
    AE: 'Emirados Árabes Unidos',
    EC: 'Equador',
    ER: 'Eritréia',
    SK: 'Eslováquia',
    SI: 'Eslovênia',
    ES: 'Espanha',
    FM: 'Estados Federados da Micronésia',
    US: 'Estados Unidos',
    EE: 'Estônia',
    SZ: 'Eswatini',
    ET: 'Etiópia',
    FK: 'Falkland Islands',
    FJ: 'Fiji',
    PH: 'Filipinas',
    FI: 'Finlândia',
    FR: 'França',
    GA: 'Gabão',
    GM: 'Gâmbia',
    GH: 'Gana',
    GE: 'Geórgia',
    GI: 'Gibraltar',
    GD: 'Granada (país)',
    GR: 'Grécia',
    GL: 'Groenlândia',
    GP: 'Guadalupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GY: 'Guiana',
    GF: 'Guiana Francesa',
    GN: 'Guiné',
    GQ: 'Guiné Equatorial',
    GW: 'Guiné-Bissau',
    HT: 'Haiti',
    NL: 'Holanda',
    HN: 'Honduras',
    HU: 'Hungria',
    YE: 'Iêmen',
    BV: 'Ilha Bouvet',
    CX: 'Ilha Christmas',
    RE: 'Ilha da Reunião',
    IM: 'Ilha de Man',
    YT: 'Ilha Mayotte',
    NF: 'Ilha Norfolk',
    AX: 'Ilhas Alanda',
    KY: 'Ilhas Cayman',
    CC: 'Ilhas Cocos',
    KM: 'Ilhas Comores',
    CK: 'Ilhas Cook',
    FO: 'Ilhas Faroé',
    GS: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    HM: 'Ilhas Heard e McDonald',
    MP: 'Ilhas Marianas do Norte',
    MH: 'Ilhas Marshall',
    MU: 'Ilhas Maurício',
    UM: 'Ilhas Menores Distantes dos EUA',
    PN: 'Ilhas Pitcairn',
    SB: 'Ilhas Salomão',
    SC: 'Ilhas Seychelles',
    VG: 'Ilhas Virgens Britânicas',
    VI: 'Ilhas Virgens E.U.A.',
    IN: 'Índia',
    ID: 'Indonésia',
    IR: 'Irã',
    IQ: 'Iraque',
    IE: 'Irlanda',
    IS: 'Islândia',
    IL: 'Israel',
    IT: 'Itália',
    JM: 'Jamaica',
    JP: 'Japão',
    JE: 'Jersey',
    JO: 'Jordânia',
    KI: 'Kiribati',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesoto',
    LV: 'Letônia',
    LB: 'Líbano',
    LR: 'Libéria',
    LY: 'Líbia',
    LI: 'Liechtenstein',
    LT: 'Lituânia',
    LU: 'Luxemburgo',
    MK: 'Macedônia do Norte',
    MG: 'Madagascar',
    MY: 'Malásia',
    MW: 'Malauí',
    MV: 'Maldivas',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marrocos',
    MQ: 'Martinica',
    MR: 'Mauritânia',
    MX: 'México',
    MZ: 'Moçambique',
    MD: 'Moldávia',
    MC: 'Mônaco',
    MN: 'Mongólia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    NA: 'Namíbia',
    NR: 'Nauru',
    NP: 'Nepal',
    NI: 'Nicarágua',
    NE: 'Níger',
    NG: 'Nigéria',
    NU: 'Niue',
    NO: 'Noruega',
    NC: 'Nova Caledônia',
    NZ: 'Nova Zelândia',
    OM: 'Omã',
    PW: 'Palau',
    PA: 'Panamá',
    PG: 'Papua Nova Guiné',
    PK: 'Paquistão',
    PY: 'Paraguai',
    PE: 'Peru',
    PF: 'Polinésia Francesa',
    PL: 'Polônia',
    PR: 'Porto Rico',
    PT: 'Portugal',
    KE: 'Quênia',
    KG: 'Quirguistão',
    HK: 'RAE de Hong Kong',
    MO: 'RAE de Macau',
    GB: 'Reino Unido',
    CF: 'República Centro-Africana',
    CZ: 'República Checa',
    CD: 'República Democrática do Congo',
    CG: 'República do Congo',
    DO: 'República Dominicana',
    SO: 'República Federal da Somália',
    RO: 'Romênia',
    RW: 'Ruanda',
    RU: 'Rússia',
    SH: 'Saint Helena',
    PM: 'Saint-Pierre e Miquelon',
    WS: 'Samoa',
    AS: 'Samoa Americana',
    SM: 'San Marino',
    LC: 'Santa Lúcia',
    KN: 'São Cristóvão e Névis',
    MF: 'São Martinho',
    ST: 'São Tomé e Príncipe',
    VC: 'São Vicente e Granadinas',
    SN: 'Senegal',
    SL: 'Serra Leoa',
    RS: 'Sérvia',
    SX: 'Sint Maarten',
    SY: 'Síria',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    SD: 'Sudão',
    SS: 'Sudão Meridional',
    SE: 'Suécia',
    CH: 'Suíça',
    SR: 'Suriname',
    SJ: 'Svalbard',
    TH: 'Tailândia',
    TW: 'Taiwan',
    TJ: 'Tajiquistão',
    TZ: 'Tanzânia',
    TF: 'Terras Austrais e Antárticas Francesas',
    IO: 'Território Britânico do Oceano Índico',
    PS: 'Territórios Palestinos',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TO: 'Tonga',
    TK: 'Toquelau',
    TT: 'Trinidad e Tobago',
    TN: 'Tunísia',
    TM: 'Turcomenistão',
    TC: 'Turcos e Caicos',
    TR: 'Turquia',
    TV: 'Tuvalu',
    UA: 'Ucrânia',
    UG: 'Uganda',
    UY: 'Uruguai',
    UZ: 'Uzbequistão',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnã',
    WF: 'Wallis e Futuna',
    ZM: 'Zâmbia',
    ZW: 'Zimbábue'
  },
  'fi-fi': {
    AF: 'Afganistan',
    AX: 'Ahvenanmaan saaristo',
    NL: 'Alankomaat',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'Amerikan Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua ja Barbuda',
    AR: 'Argentiina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AZ: 'Azerbaidžan',
    BS: 'Bahama',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BE: 'Belgia',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius ja Saba',
    BA: 'Bosnia ja Hertsegovina',
    BW: 'Botswana',
    BV: 'Bouvet’nsaari',
    BR: 'Brasilia',
    IO: 'Brittiläinen Intian valtameren alue',
    VG: 'Brittiläiset Neitsytsaaret',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KY: 'Caymansaaret',
    CL: 'Chile',
    CK: 'Cookinsaaret',
    CR: 'Costa Rica',
    CW: 'Curaçao',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominikaaninen tasavalta',
    EC: 'Ecuador',
    EG: 'Egypti',
    SV: 'El Salvador',
    ER: 'Eritrea',
    ES: 'Espanja',
    SZ: 'Eswatini',
    ZA: 'Etelä-Afrikka',
    GS: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
    KR: 'Etelä-Korea',
    SS: 'Etelä-Sudan',
    ET: 'Etiopia',
    FK: 'Falklandinsaaret',
    FJ: 'Fidži',
    PH: 'Filippiinit',
    FO: 'Färsaaret',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenada',
    GL: 'Grönlanti',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard ja McDonaldinsaaret',
    HN: 'Honduras',
    HK: 'Hongkongin Kiinan kansantasavallan erityishallintoalue',
    SJ: 'Huippuvuoret',
    ID: 'Indonesia',
    IN: 'Intia',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irlanti',
    IS: 'Islanti',
    IL: 'Israel',
    IT: 'Italia',
    TL: 'Itä-Timor',
    AT: 'Itävalta',
    JM: 'Jamaika',
    JP: 'Japani',
    YE: 'Jemen',
    JE: 'Jersey',
    JO: 'Jordania',
    CX: 'Joulusaari',
    KH: 'Kambodža',
    CM: 'Kamerun',
    CA: 'Kanada',
    CV: 'Kap Verde',
    KZ: 'Kazakstan',
    KE: 'Kenia',
    CF: 'Keski-Afrikan tasavalta',
    CN: 'Kiina',
    KG: 'Kirgistan',
    KI: 'Kiribati',
    CO: 'Kolumbia',
    KM: 'Komorit',
    CD: 'Kongon demokraattinen tasavalta',
    CG: 'Kongon tasavalta',
    CC: 'Kookossaaret',
    GR: 'Kreikka',
    HR: 'Kroatia',
    CU: 'Kuuba',
    KW: 'Kuwait',
    CY: 'Kypros',
    LA: 'Laos',
    LV: 'Latvia',
    LS: 'Lesotho',
    LB: 'Libanon',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Liettua',
    LU: 'Luxemburg',
    MO: 'Macaon Kiinan kansantasavallan erityishallintoalue',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MV: 'Malediivit',
    MY: 'Malesia',
    ML: 'Mali',
    MT: 'Malta',
    IM: 'Mansaari',
    MA: 'Marokko',
    MH: 'Marshallinsaaret',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Meksiko',
    FM: 'Mikronesian liittovaltio',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mosambik',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolkinsaari',
    NO: 'Norja',
    CI: 'Norsunluurannikko',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestiinalaisalue',
    PA: 'Panama',
    PG: 'Papua Uusi-Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PN: 'Pitcairnsaaret',
    KP: 'Pohjois-Korea',
    MK: 'Pohjois-Makedonia',
    MP: 'Pohjois-Mariaanit',
    PT: 'Portugali',
    PR: 'Puerto Rico',
    PL: 'Puola',
    GQ: 'Päiväntasaajan Guinea',
    QA: 'Qatar',
    FR: 'Ranska',
    TF: 'Ranskan eteläisten ja antarktisten alueiden territorio',
    GF: 'Ranskan Guayana',
    PF: 'Ranskan Polynesia',
    RE: 'Réunion',
    RO: 'Romania',
    RW: 'Ruanda',
    SE: 'Ruotsi',
    KN: 'Saint Kitts ja Nevis',
    LC: 'Saint Lucia',
    VC: 'Saint Vincent ja Grenadiinit',
    MF: 'Saint-Martin',
    PM: 'Saint-Pierre ja Miquelon',
    DE: 'Saksa',
    SB: 'Salomonsaaret',
    ZM: 'Sambia',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé ja Príncipe',
    SA: 'Saudi-Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychellit',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SO: 'Somalian tasavalta',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    SH: 'St. Helena',
    SD: 'Sudan',
    FI: 'Suomi',
    SR: 'Suriname',
    CH: 'Sveitsi',
    SY: 'Syyria',
    TJ: 'Tadžikistan',
    TW: 'Taiwan',
    TZ: 'Tansania',
    DK: 'Tanska',
    TH: 'Thaimaa',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad ja Tobago',
    TD: 'Tšad',
    CZ: 'Tšekki',
    TN: 'Tunisia',
    TR: 'Turkki',
    TM: 'Turkmenistan',
    TC: 'Turks- ja Caicossaaret',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraina',
    HU: 'Unkari',
    UY: 'Uruguay',
    NC: 'Uusi-Kaledonia',
    NZ: 'Uusi-Seelanti',
    UZ: 'Uzbekistan',
    BY: 'Valko-Venäjä',
    VU: 'Vanuatu',
    VA: 'Vatikaanivaltio',
    VE: 'Venezuela',
    RU: 'Venäjä',
    VN: 'Vietnam',
    EE: 'Viro',
    WF: 'Wallis ja Futuna',
    AE: 'Yhdistyneet arabiemiirikunnat',
    GB: 'Yhdistynyt kuningaskunta',
    US: 'Yhdysvallat',
    VI: 'Yhdysvaltain Neitsytsaaret',
    UM: 'Yhdysvaltain pienet erillissaaret',
    ZW: 'Zimbabwe'
  },
  'nb-no': {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algerie',
    AS: 'Amerikansk Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua og Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AZ: 'Aserbajdsjan',
    AU: 'Australia',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgia',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Saint Eustatius og Saba',
    BA: 'Bosnia-Hercegovina',
    BW: 'Botswana',
    BV: 'Bouvetøya',
    BR: 'Brasil',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CA: 'Canada',
    KY: 'Caymanøyene',
    CL: 'Chile',
    CX: 'Christmas Island',
    CC: 'Cocos-øyene',
    CO: 'Colombia',
    CK: 'Cookøyene',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CW: 'Curaçao',
    DK: 'Danmark',
    VI: 'De amerikanske Jomfruøyene',
    VG: 'De britiske Jomfruøyene',
    AE: 'De forente arabiske emirater',
    PS: 'De palestinske territoriene',
    CD: 'Den demokratiske republikken Kongo',
    DO: 'Den dominikanske republikk',
    CF: 'Den sentralafrikanske republikk',
    IO: 'Det britiske territorium i Det indiske hav',
    DJ: 'Djibouti',
    DM: 'Dominica',
    EC: 'Ecuador',
    EG: 'Egypt',
    GQ: 'Ekvatorial Guinea',
    SV: 'El Salvador',
    CI: 'Elfenbenskysten',
    ER: 'Eritrea',
    EE: 'Estland',
    SZ: 'Eswatini',
    ET: 'Etiopia',
    FK: 'Falklandsøyene',
    SO: 'Federal Republic of Somalia',
    FJ: 'Fiji',
    PH: 'Filippinene',
    FI: 'Finland',
    FR: 'Frankrike',
    GF: 'Fransk Guiana',
    PF: 'Fransk Polynesia',
    TF: 'Franske sørlige og antarktiske territorier',
    FO: 'Færøyene',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenada',
    GL: 'Grønland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard og McDonald Islands',
    GR: 'Hellas',
    HN: 'Honduras',
    HK: 'Hongkong SAR',
    IN: 'India',
    ID: 'Indonesia',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irland',
    IS: 'Island',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italia',
    JM: 'Jamaica',
    JP: 'Japan',
    YE: 'Jemen',
    JE: 'Jersey',
    JO: 'Jordan',
    KH: 'Kambodsja',
    CM: 'Kamerun',
    CV: 'Kapp Verde',
    KZ: 'Kasakhstan',
    KE: 'Kenya',
    CN: 'Kina',
    KG: 'Kirgisistan',
    KI: 'Kiribati',
    KM: 'Komorene',
    CG: 'Kongo-Brazzaville',
    HR: 'Kroatia',
    KW: 'Kuwait',
    CY: 'Kypros',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxembourg',
    MO: 'Macao SAR',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldivene',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marokko',
    MH: 'Marshalløyene',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Mikronesiaføderasjonen',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mosambik',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NL: 'Nederland',
    NP: 'Nepal',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    KP: 'Nord Korea',
    MK: 'Nord-Makedonia',
    MP: 'Nord-Marianene',
    NF: 'Norfolkøya',
    NO: 'Norge',
    NC: 'Ny-Caledonia',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PA: 'Panama',
    PG: 'Papua Ny-Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PN: 'Pitcairnøyene',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russland',
    RW: 'Rwanda',
    MF: 'Saint Martin',
    PM: 'Saint-Pierre-et-Miquelon',
    SB: 'Salomonøyene',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé og Príncipe',
    SA: 'Saudi-Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychellene',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    ES: 'Spania',
    LK: 'Sri Lanka',
    BL: 'St. Barthelemy',
    SH: 'St. Helena',
    KN: 'St. Kitts og Nevis',
    LC: 'St. Lucia',
    VC: 'St. Vincent og Grenadinene',
    GB: 'Storbritannia',
    SD: 'Sudan',
    SR: 'Surinam',
    SJ: 'Svalbard',
    CH: 'Sveits',
    SE: 'Sverige',
    SY: 'Syria',
    ZA: 'Sør-Afrika',
    GS: 'Sør-Georgia og Sør-Sandwich-øyene',
    KR: 'Sør-Korea',
    SS: 'Sør-Sudan',
    TJ: 'Tadsjikistan',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad og Tobago',
    TD: 'Tsjad',
    CZ: 'Tsjekkia',
    TN: 'Tunisia',
    TM: 'Turkmenistan',
    TC: 'Turks og Caicosøyene',
    TV: 'Tuvalu',
    TR: 'Tyrkia',
    DE: 'Tyskland',
    UG: 'Uganda',
    UA: 'Ukraina',
    HU: 'Ungarn',
    UY: 'Uruguay',
    US: 'USA',
    UM: 'USAs ytre småøyer',
    UZ: 'Usbekistan',
    VU: 'Vanuatu',
    VA: 'Vatikanstaten',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis og Futunaøyene',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AT: 'Østerrike',
    AX: 'Åland'
  },
  'da-dk': {
    AF: 'Afghanistan',
    AL: 'Albanien',
    DZ: 'Algeriet',
    AS: 'Amerikansk Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua og Barbuda',
    AR: 'Argentina',
    AM: 'Armenien',
    AW: 'Aruba',
    AZ: 'Aserbajdsjan',
    AU: 'Australien',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgien',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius og Saba',
    BA: 'Bosnien-Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvetøen',
    BR: 'Brasilien',
    VG: 'Britiske Jomfruøer',
    BN: 'Brunei',
    BG: 'Bulgarien',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodja',
    CM: 'Cameroun',
    CA: 'Canada',
    KY: 'Caymanøerne',
    CL: 'Chile',
    CX: 'Christmas Island',
    CC: 'Cocosøerne',
    CO: 'Colombia',
    KM: 'Comorerne',
    CK: 'Cookøerne',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cypern',
    DK: 'Danmark',
    VI: 'De Amerikanske Jomfruøer',
    AE: 'De Forenede Arabiske Emirater',
    TF: 'De franske besiddelser i Det Sydlige Indiske Ocean og Antarktis',
    CF: 'Den Centralafrikanske Republik',
    CD: 'Den Demokratiske Republik Congo',
    DO: 'Den Dominikanske Republik',
    SO: 'Den Føderale Republik Somalia',
    IO: 'Det Britiske Territorium i Det Indiske Ocean',
    DJ: 'Djibouti',
    DM: 'Dominica',
    EC: 'Ecuador',
    EG: 'Egypten',
    SV: 'El Salvador',
    CI: 'Elfenbenskysten',
    ER: 'Eritrea',
    EE: 'Estland',
    SZ: 'Eswatini',
    ET: 'Etiopien',
    FK: 'Falklandsøerne',
    FJ: 'Fiji',
    PH: 'Filippinerne',
    FI: 'Finland',
    FR: 'Frankrig',
    GF: 'Fransk Guiana',
    PF: 'Fransk Polynesien',
    FO: 'Færøerne',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgien',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenada',
    GR: 'Grækenland',
    GL: 'Grønland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard og McDonald Islands',
    NL: 'Holland',
    HN: 'Honduras',
    HK: 'Hongkong SAR',
    IN: 'Indien',
    ID: 'Indonesien',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irland',
    IS: 'Island',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italien',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    CV: 'Kap Verde',
    KZ: 'Kasakhstan',
    KE: 'Kenya',
    CN: 'Kina',
    KG: 'Kirgisistan',
    KI: 'Kiribati',
    HR: 'Kroatien',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Letland',
    LB: 'Libanon',
    LR: 'Liberia',
    LY: 'Libyen',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxembourg',
    MO: 'Macao SAR',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldiverne',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marokko',
    MH: 'Marshalløerne',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Mikronesiens Forenede Stater',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongoliet',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    KP: 'Nordkorea',
    MK: 'Nordmakedonien',
    MP: 'Nordmarianerne',
    NF: 'Norfolk Island',
    NO: 'Norge',
    NC: 'Ny Kaledonien',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palæstinensiske Territorier',
    PA: 'Panama',
    PG: 'Papua Ny Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PN: 'Pitcairn Islands',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    CG: 'Republikken Congo',
    RE: 'Réunion',
    RO: 'Rumænien',
    RU: 'Rusland',
    RW: 'Rwanda',
    KN: 'Saint Kitts og Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    VC: 'Saint Vincent og Grenadinerne',
    BL: 'Saint-Barthélemy',
    PM: 'Saint-Pierre og Miquelon',
    WS: 'Samoa',
    SM: 'San Marino',
    SH: 'Sankt Helena',
    ST: 'São Tomé og Príncipe',
    SA: 'Saudi-Arabien',
    CH: 'Schweiz',
    SN: 'Senegal',
    RS: 'Serbien',
    SC: 'Seychellerne',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakiet',
    SI: 'Slovenien',
    SB: 'Solomonøerne',
    GS: 'South Georgia og South Sandwich Islands',
    ES: 'Spanien',
    LK: 'Sri Lanka',
    GB: 'Storbritannien',
    SD: 'Sudan',
    SR: 'Surinam',
    SJ: 'Svalbard',
    SE: 'Sverige',
    ZA: 'Sydafrika',
    KR: 'Sydkorea',
    SS: 'Sydsudan',
    SY: 'Syrien',
    TJ: 'Tadsjikistan',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    TD: 'Tchad',
    TH: 'Thailand',
    CZ: 'Tjekkiet',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad og Tobago',
    TN: 'Tunesien',
    TM: 'Turkmenistan',
    TC: 'Turks- og Caicosøerne',
    TV: 'Tuvalu',
    TR: 'Tyrkiet',
    DE: 'Tyskland',
    UG: 'Uganda',
    UA: 'Ukraine',
    HU: 'Ungarn',
    UY: 'Uruguay',
    US: 'USA',
    UM: "USA's ydre småøer",
    UZ: 'Usbekistan',
    VU: 'Vanuatu',
    VA: 'Vatikanstaten',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis og Futuna',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    GQ: 'Ækvatorialguinea',
    AT: 'Østrig',
    TL: 'Østtimor',
    AX: 'Åland'
  },
  'sv-se': {
    AF: 'Afghanistan',
    AL: 'Albanien',
    DZ: 'Algeriet',
    VI: 'Amerikanska Jungfruöarna',
    AS: 'Amerkanska Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua och Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australien',
    AZ: 'Azerbajdzjan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgien',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius och Saba',
    BA: 'Bosnien-Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet-ön',
    BR: 'Brasilien',
    VG: 'Brittiska Jungfruöarna',
    IO: 'Brittiska territoriet i Indiska oceanen',
    BN: 'Brunei',
    BG: 'Bulgarien',
    BF: 'Burkina Faso',
    MM: 'Burma (Myanmar)',
    BI: 'Burundi',
    KY: 'Caymanöarna',
    CF: 'Centralafrikanska republiken',
    CL: 'Chile',
    CO: 'Colombia',
    KM: 'Comorerna',
    CK: 'Cook-öarna',
    CR: 'Costa Rica',
    CW: 'Curaçao',
    CY: 'Cypern',
    DK: 'Danmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominikanska republiken',
    EC: 'Ecuador',
    EG: 'Egypten',
    GQ: 'Ekvatorialguinea',
    SV: 'El Salvador',
    CI: 'Elfenbenskusten',
    ER: 'Eritrea',
    EE: 'Estland',
    ET: 'Etiopien',
    FK: 'Falklandsöarna',
    FJ: 'Fidji',
    PH: 'Filippinerna',
    FI: 'Finland',
    FR: 'Frankrike',
    GF: 'Franska Guyana',
    PF: 'Franska polynesien',
    TF: 'Franska syd- och Antarktis-territorierna',
    FO: 'Färöarna',
    SO: 'Förbundsrepubliken Somalia',
    AE: 'Förenade Arabemiraten',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgien',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Grekland',
    GD: 'Grenada',
    GL: 'Grönland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard- och McDonald-öarna',
    HN: 'Honduras',
    HK: 'Hongkong SAR',
    IN: 'Indien',
    ID: 'Indonesien',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irland',
    IS: 'Island',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italien',
    JM: 'Jamaica',
    JP: 'Japan',
    YE: 'Jemen',
    JE: 'Jersey',
    JO: 'Jordanien',
    CX: 'Julön',
    KH: 'Kambodja',
    CM: 'Kamerun',
    CA: 'Kanada',
    CV: 'Kap Verde',
    KZ: 'Kazakstan',
    KE: 'Kenya',
    CN: 'Kina',
    KG: 'Kirgizistan',
    KI: 'Kiribati',
    CC: 'Kokosöarna',
    CD: 'Kongo-Kinshasa',
    HR: 'Kroatien',
    CU: 'Kuba',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Lettland',
    LB: 'Libanon',
    LR: 'Liberia',
    LY: 'Libyen',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxemburg',
    MO: 'Macau SAR',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldiverna',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marocko',
    MH: 'Marshallöarna',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexiko',
    FM: 'Mikronesien',
    MD: 'Moldavien',
    MC: 'Monaco',
    MN: 'Mongoliet',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NR: 'Nauru',
    NL: 'Nederländerna',
    NP: 'Nepal',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    KP: 'Nordkorea',
    MK: 'Nordmakedonien',
    MP: 'Nordmarianerna',
    NF: 'Norfolk-ön',
    NO: 'Norge',
    NC: 'Nya Kaledonien',
    NZ: 'Nya Zeeland',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinska territorierna',
    PA: 'Panama',
    PG: 'Papua Nya Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PN: 'Pitcairnöarna',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    CG: 'Republiken Kongo',
    RE: 'Reunion',
    RO: 'Rumänien',
    RW: 'Rwanda',
    RU: 'Ryssland',
    MF: 'Saint-Martin',
    SB: 'Salomon-öarna',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé och Príncipe',
    SA: 'Saudiarabien',
    CH: 'Schweiz',
    SN: 'Senegal',
    RS: 'Serbien',
    SC: 'Seychellerna',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakien',
    SI: 'Slovenien',
    ES: 'Spanien',
    LK: 'Sri Lanka',
    BL: 'St. Bartolomeus',
    SH: 'St. Helena',
    KN: 'St. Kitts och Nevis',
    LC: 'St. Lucia',
    PM: 'St. Pierre-et-Miquelon',
    VC: 'St. Vincent och Grenadinerna',
    GB: 'Storbritannien',
    SD: 'Sudan',
    SR: 'Surinam',
    SJ: 'Svalbard',
    SE: 'Sverige',
    SZ: 'Swaziland',
    ZA: 'Sydafrika',
    GS: 'Sydgeorgien och Sydsandwichöarna',
    KR: 'Sydkorea',
    SY: 'Syrien',
    SS: 'Södra Sudan',
    TJ: 'Tadzjikistan',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    TD: 'Tchad',
    TH: 'Thailand',
    TL: 'Timor Leste',
    CZ: 'Tjeckien',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad och Tobago',
    TN: 'Tunisien',
    TR: 'Turkiet',
    TM: 'Turkmenistan',
    TC: 'Turks- och Caicosöarna',
    TV: 'Tuvalu',
    DE: 'Tyskland',
    UG: 'Uganda',
    UA: 'Ukraina',
    HU: 'Ungern',
    UY: 'Uruguay',
    UM: 'US Minor Outlying Islands',
    US: 'USA',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis och Futuna',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AX: 'Åland',
    AT: 'Österrike'
  }
};