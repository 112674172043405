import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { NavLink, useMatch } from 'react-router-dom-v5-compat';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkText } from 'uitk-react-text';
import { classNames } from 'uitk-react-utils';
import { badgeItem } from 'shared/components/badges/badge';
export var NavItem = function (_a) {
  // const { pageName } = usePageContext();
  // const trackUserEvent = useTrackUserEvent();
  var to = _a.to,
    text = _a.text,
    icon = _a.icon,
    _b = _a.isLoading,
    isLoading = _b === void 0 ? false : _b,
    size = _a.size,
    className = _a.className,
    isDisabled = _a.isDisabled,
    badge = _a.badge,
    _c = _a.showText,
    showText = _c === void 0 ? true : _c;
  var match = useMatch(to);
  var linkClasses = useMemo(function () {
    return classNames(className, 'NavItem', {
      NavItem__match: match,
      NavItem__disabled: isDisabled,
      NavItem__isLoading: isLoading
    });
  }, [className, isDisabled, isLoading, match]);
  var handleClick = useCallback(function (event) {
    if (isDisabled) {
      event.preventDefault();
      /*
      } else {
      if (icon == 'local_shopping') {
        trackUserEvent('affhub_nav_shop.selected');
      }
      trackUserEvent('affhub_global_nav_tab.selected', pageName, {
        additional_context: { user_interface: { component_name: pageName } },
      });
            */
    }
  }, [isDisabled]);
  return React.createElement("li", null, React.createElement(NavLink, {
    className: linkClasses,
    to: to,
    onClick: handleClick,
    end: true
  }, React.createElement("div", {
    className: "NavItem__box"
  }, React.createElement(UitkIcon, {
    className: "NavItem__icon",
    name: icon,
    size: UitkIconSize.MEDIUM
  }), React.createElement(UitkText, {
    weight: "regular",
    inline: true,
    size: size || 300,
    className: classNames('NavItem__text', showText ? '' : 'NavItem__text--hidden')
  }, text)), badge && showText && badgeItem[badge], !showText && React.createElement("div", {
    style: {
      left: 'calc(100% - 4px)'
    },
    className: "NavItem__text2"
  }, text)));
};