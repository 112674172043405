import React from 'react';
import { LocalizedText } from '@shared-ui/localization-context';
import { EGDSText } from '@egds/react-core';
export function FooterCopyright() {
  var currentYear = new Date().getFullYear();
  return React.createElement(EGDSText, {
    size: 200
  }, React.createElement(LocalizedText, {
    message: "footer.content.all_rights_reserved",
    data: [currentYear]
  }));
}