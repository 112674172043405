import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { PROFILE_PATH } from 'features/app/account/profile/profile.controller';
import { SETTINGS_PATH } from 'features/app/account/settings/settings.controller';
import { useLocalization } from '@shared-ui/localization-context';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { UitkButtonSize, UitkSecondaryButton } from 'uitk-react-button';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkMenu, UitkMenuContainer, UitkMenuList, UitkMenuTrigger } from 'uitk-react-menu';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { classNames } from 'uitk-react-utils';
import { useIsomorphicState } from 'shared/hooks/isomorphic-state';
import { useLayoutNavigation } from '../../hooks/use-layout-navigation';
import { useMenu } from '../../hooks/use-menu';
export function AccountNav() {
  var user = useIsomorphicState(function (state) {
    return state.user;
  });
  var navigate = useNavigate();
  var l10n = useLocalization();
  var hasRestrictedAccess = useLayoutNavigation(user).hasRestrictedAccess;
  var _a = useMenu(),
    menuOpen = _a.menuOpen,
    setMenuOpen = _a.setMenuOpen;
  var helpLink = 'https://help.affiliates.expediagroup.com/hc/en-us';
  var menuItems = useMemo(function () {
    return [{
      attributes: {
        role: 'menuitem',
        tabindex: 0
      },
      // TODO: remove custom css class once upgrade to uitk-react-bundle-core with uitk-react-menu at least of 10.5.0 (has disabled property in config)
      className: classNames('uitk-list-item'),
      label: React.createElement(UitkText, {
        weight: "medium",
        inline: true,
        size: 300
      }, l10n.formatText('account.tab-navigation.profile')),
      icon: {
        name: 'person'
      },
      typeAnchor: true,
      onClick: handleNavigateToProfile,
      disabled: hasRestrictedAccess
    }, {
      attributes: {
        role: 'menuitem',
        tabindex: 1
      },
      // TODO: remove custom css class once upgrade to uitk-react-bundle-core with uitk-react-menu at least of 10.5.0 (has disabled property in config)
      className: classNames('uitk-list-item'),
      label: React.createElement(UitkText, {
        weight: "medium",
        inline: true,
        size: 300
      }, l10n.formatText('account.tab-navigation.settings')),
      icon: {
        name: 'settings'
      },
      typeAnchor: true,
      onClick: handleNavigateToSettings,
      disabled: hasRestrictedAccess
    }, {
      attributes: {
        role: 'menuitem',
        tabindex: 2
      },
      // TODO: remove custom css class once upgrade to uitk-react-bundle-core with uitk-react-menu at least of 10.5.0 (has disabled property in config)
      className: classNames('uitk-list-item'),
      label: React.createElement(UitkText, {
        weight: "medium",
        inline: true,
        size: 300
      }, l10n.formatText('common.help')),
      icon: {
        name: 'help'
      },
      typeAnchor: true,
      onClick: handleNavigationToHelpCenter,
      disabled: hasRestrictedAccess
    }, {
      attributes: {
        role: 'menuitem',
        tabindex: 3
      },
      className: 'uitk-list-item Logout__link',
      label: React.createElement(UitkText, {
        weight: "medium",
        inline: true,
        size: 300
      }, l10n.formatText('common.sign_out')),
      icon: {
        name: 'log_out'
      },
      typeAnchor: true,
      onClick: handleLogout
    }];
  }, [hasRestrictedAccess, l10n.formatText]);
  function handleMenuClick() {
    setMenuOpen(!menuOpen);
    // trackUserEvent('affhub_account.selected');
  }
  function handleLogout() {
    navigate('/auth/logout', {
      replace: true
    });
  }
  function handleNavigateToProfile() {
    navigate(PROFILE_PATH);
  }
  function handleNavigateToSettings() {
    navigate(SETTINGS_PATH);
  }
  function handleNavigationToHelpCenter() {
    window.open(helpLink, '_blank');
  }
  /*
  const accountNavClasses = useMemo(
    () =>
      classNames('transition-all duration-[375ms] ease-in-out text-[#191e3b]', {
        'w-[325px]': !isMobile,
        'w-screen rounded-b-2xl [&&]:mt-[6px] top-[60px] absolute': isMobile,
      }),
    [isMobile]
  );
   */
  // TODO Mobile
  return React.createElement("div", {
    className: "AccountNav"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    className: "no-bullet",
    direction: "row",
    tag: "ul"
  }, React.createElement("li", null, React.createElement(UitkMenu, {
    isOpen: menuOpen,
    onTriggerClick: handleMenuClick,
    id: "account-nav"
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkSecondaryButton, {
    className: "AccountNav__button",
    inverse: true,
    size: UitkButtonSize.LARGE,
    "data-stid": "account-nav-btn"
  }, React.createElement(UitkIcon, {
    name: "account_circle",
    size: UitkIconSize.XLARGE
  }))), React.createElement(UitkMenuContainer, {
    autoPosition: true
  }, React.createElement("div", {
    className: "AccountNav__menu"
  }, React.createElement(UitkHeading, {
    tag: "h5",
    size: 6
  }, user === null || user === void 0 ? void 0 : user.firstName, " ", user === null || user === void 0 ? void 0 : user.lastName), React.createElement(UitkText, {
    size: 200
  }, user === null || user === void 0 ? void 0 : user.email)), React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(UitkSpacingHr, null)), React.createElement(ViewLarge, null)), React.createElement(UitkMenuList, {
    items: menuItems
  }))))));
}