import React from 'react';
import { useLocalization } from '@shared-ui/localization-context';
import { UitkBadgeStandard } from 'uitk-react-badge';
export function NewBadge() {
  var l10n = useLocalization();
  return React.createElement(UitkBadgeStandard, {
    size: "small",
    text: l10n.formatText('common.new'),
    theme: "positive"
  });
}