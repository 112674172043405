import React from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { usePlatformContext } from '@bernie/platform-context';
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing, UitkSpacingHr } from 'uitk-react-spacing';
import { UitkHeading } from 'uitk-react-text';
import { FooterCopyright } from 'shared/components/footer/footer-copyright';
import { DirectWord } from './direct-word';
var ExpandFooter = function () {
  var locale = usePlatformContext().context.locale;
  return React.createElement(UitkSpacing, {
    padding: {
      block: 'twelve'
    },
    border: ['blockstart'],
    className: "ExpandFooter light-section-bg"
  }, React.createElement("footer", null, React.createElement("div", {
    className: "container"
  }, React.createElement(UitkLink, null, React.createElement(Link, {
    to: "/home"
  }, React.createElement("img", {
    className: "Header__logo",
    src: "/images/shared/eg-affiliate-program-logo-blue.svg",
    alt: "Expedia Group Affiliate Program"
  }))), React.createElement(UitkSpacing, {
    margin: {
      block: 'twelve'
    }
  }, React.createElement(UitkLayoutGrid, {
    columns: ['1fr', '1fr'],
    space: "four"
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(UitkHeading, {
    size: 6
  }, React.createElement(LocalizedText, {
    message: "footer.heading.support"
  }))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkHeading, {
    size: 6
  }, React.createElement(LocalizedText, {
    message: "footer.heading.policies"
  }))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement("a", {
    href: "https://help.affiliates.expediagroup.com/hc/en-us",
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.help_center"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement(Link, {
    to: "/doc/privacy-policy?locale=".concat(locale),
    target: "_blank"
  }, React.createElement(LocalizedText, {
    message: "footer.links.privacy_policy"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement("a", {
    href: "https://help.affiliates.expediagroup.com/hc/en-us/categories/13657662298775",
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.resources"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement(Link, {
    to: "/doc/affiliate?locale=".concat(locale),
    target: "_blank"
  }, React.createElement(LocalizedText, {
    message: "footer.links.program_terms"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement("a", {
    href: "mailto:expedia.support@partnerize.com",
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.contact_us"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement(Link, {
    to: "/doc/cookie-policy?locale=".concat(locale),
    target: "_blank"
  }, React.createElement(LocalizedText, {
    message: "footer.links.cookie_policy"
  })))))), React.createElement("div", {
    className: "Footer__hr"
  }, React.createElement(UitkSpacingHr, {
    blockMargin: 'six'
  })), React.createElement(FooterCopyright, null), React.createElement("div", {
    className: "Footer__feedback"
  }, React.createElement(DirectWord, {
    showIcon: true,
    display: "floating"
  })))));
};
export { ExpandFooter };