import React from 'react';
import { usePageData } from '@bernie/page-context';
import { usePlatformContext } from '@bernie/platform-context';
import { useLocalization } from '@shared-ui/localization-context';
// UITK
import { UitkButtonSize, UitkFloatingButton, UitkSecondaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLink } from 'uitk-react-link';
import { classNames } from 'uitk-react-utils';
var DirectWord = function (_a) {
  var display = _a.display,
    text = _a.text,
    showIcon = _a.showIcon;
  var url;
  var l10n = useLocalization();
  var locale = usePlatformContext().context.locale;
  var pageName = usePageData().pageDataContext.pageName;
  var classes = classNames({
    'DirectWord--inline': display === 'inline',
    'DirectWord--floating': display === 'floating'
  });
  var defaultText = l10n.formatText('common.feedback');
  // Fix SSR error where window doesn't exit
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }
  var data = {
    pagename: pageName,
    url: url
  };
  var metaData = encodeURIComponent(JSON.stringify(data));
  var dwLocale = locale === null || locale === void 0 ? void 0 : locale.toLowerCase().replace('_', '-');
  var dwLink = "https://www.directword.io/survey/domain=affiliates.expediagroup.com/locale=".concat(dwLocale, "?metadata=").concat(metaData);
  function handleClick() {
    window.open(dwLink);
  }
  // some of these are no longer used and perhaps could be removed.
  switch (display) {
    case 'block':
      return React.createElement(UitkSecondaryButton, {
        className: classes,
        size: UitkButtonSize.SMALL,
        isFullWidth: true,
        onClick: handleClick
      }, showIcon && React.createElement(UitkIcon, {
        name: "comment"
      }), text || defaultText);
    default:
    case 'inline':
      return React.createElement(UitkSecondaryButton, {
        className: classes,
        onClick: handleClick
      }, showIcon && React.createElement(UitkIcon, {
        name: "comment"
      }), text || defaultText);
    case 'floating':
      return React.createElement(UitkFloatingButton, {
        className: classes,
        onClick: handleClick
      }, showIcon && React.createElement(UitkIcon, {
        name: "comment"
      }), text || defaultText);
    case 'link':
      return React.createElement(UitkLink, {
        className: "DirectWord__link",
        size: "large",
        inline: true
      }, React.createElement("a", {
        href: dwLink,
        target: "_blank",
        rel: "noopener noreferrer"
      }, text || defaultText));
    case 'link-small':
      return React.createElement(UitkLink, {
        size: "small"
      }, React.createElement("a", {
        href: dwLink,
        target: "_blank",
        rel: "noreferrer"
      }, showIcon && React.createElement(UitkIcon, {
        name: "comment"
      }), text || defaultText));
  }
};
export { DirectWord };