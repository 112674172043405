import React from 'react';
import { useLocalization } from '@shared-ui/localization-context';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { AuthenticatedLayout } from 'shared/authenticated-layout/authenticated-layout';
import { PageHeader } from 'shared/components/page-header/page-header';
import { AccountTabs } from '../components/account-tabs';
export function AccountShell(_a) {
  var children = _a.children;
  var l10n = useLocalization();
  return React.createElement("div", {
    className: "AccountShell"
  }, React.createElement(AuthenticatedLayout, null, React.createElement(EGDSSpacing, {
    margin: 'twelve'
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(EGDSSpacing, {
    margin: {
      blockend: 'six'
    }
  }, React.createElement("div", null, React.createElement(PageHeader, {
    title: l10n.formatText('account.page.title')
  }), React.createElement(AccountTabs, null))), children))));
}