export var Condition;
(function (Condition) {
  Condition[Condition["TravelShopsEnabled"] = 1] = "TravelShopsEnabled";
  Condition[Condition["TravelShopCompleted"] = 2] = "TravelShopCompleted";
  Condition[Condition["TravelVideos"] = 3] = "TravelVideos";
  Condition[Condition["TravelVideosBeta"] = 4] = "TravelVideosBeta";
  Condition[Condition["TravelVideoPerformance"] = 5] = "TravelVideoPerformance";
  Condition[Condition["CreatorToolbox"] = 6] = "CreatorToolbox";
  Condition[Condition["AccountLinking"] = 7] = "AccountLinking";
  Condition[Condition["RemovePZv1"] = 8] = "RemovePZv1";
  Condition[Condition["RemovePZv2"] = 9] = "RemovePZv2";
  Condition[Condition["CommissionTransactions"] = 10] = "CommissionTransactions";
  Condition[Condition["Payouts"] = 11] = "Payouts";
  Condition[Condition["FirstMileCompleted"] = 12] = "FirstMileCompleted";
  Condition[Condition["FirstMileLegacyUser"] = 13] = "FirstMileLegacyUser";
})(Condition || (Condition = {}));
export var useConditions = function () {
  // useExperiment()
  var _a;
  // TODO create functions for setting of these and put each in useMemo() or useCallback().
  return _a = {}, _a[Condition.TravelShopsEnabled] = true,
  // experiment enabled, affiliate in Early Access list
  _a[Condition.TravelShopCompleted] = false,
  // experiment enabled, affiliate in Early Access list, and Travel Shop completed
  _a[Condition.TravelVideos] = true,
  // videos experiment bucket = 1
  _a[Condition.TravelVideosBeta] = true,
  // videos experiment bucket ... anything else?
  _a[Condition.TravelVideoPerformance] = true, _a[Condition.CreatorToolbox] = true,
  // always true?
  _a[Condition.AccountLinking] = true,
  // Different from CreatorToolBox how?
  _a[Condition.RemovePZv1] = true, _a[Condition.RemovePZv2] = true, _a[Condition.CommissionTransactions] = true,
  // ??
  _a[Condition.Payouts] = true,
  // has PZ API key or is Network user
  _a[Condition.FirstMileCompleted] = true,
  // preferences.firstMileComplete
  _a[Condition.FirstMileLegacyUser] = true, _a;
};