// 3rd party imports
import * as React from 'react';
import { TagCommanderLoader } from 'shared/components/tag-commander-loader';
import { ExpandFooter } from './expand-footer';
import { Header } from './header';
export var Layout = function (_a) {
  var children = _a.children,
    centered = _a.centered;
  var environment = process.env.NODE_ENV === 'production' ? 'production' : 'development';
  return React.createElement("div", {
    className: "Layout"
  }, React.createElement(Header, null), React.createElement("section", {
    className: "Layout__body ".concat(centered ? 'Layout__body--centered' : '')
  }, children), React.createElement(ExpandFooter, null), React.createElement(TagCommanderLoader, {
    environment: environment
  }));
};