import React from 'react';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
export var PageHeader = function (_a) {
  var children = _a.children,
    title = _a.title,
    description = _a.description,
    notificationContent = _a.notificationContent,
    badge = _a.badge;
  var showNotification = notificationContent && React.createElement(UitkSpacing, {
    margin: {
      blockend: 'six'
    }
  }, notificationContent);
  var showBadge = badge ? React.createElement("div", {
    className: "PageHeader__Badge"
  }, React.createElement(UitkHeading, {
    size: 3
  }, title), badge) : React.createElement(UitkHeading, {
    size: 3
  }, title);
  var showDescription = description && React.createElement(UitkText, {
    size: 300,
    weight: "regular",
    className: "PageHeader__DescriptionText"
  }, description);
  return React.createElement("header", {
    className: "PageHeader",
    "data-stid": "page-header"
  }, React.createElement("div", {
    className: "container"
  }, showNotification, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement("div", {
    className: "PageHeader__Content--mobile"
  }, React.createElement("div", {
    className: "PageHeader__Description--mobile"
  }, showBadge, showDescription), children && React.createElement("div", {
    className: "PageHeader__Children--mobile"
  }, children))), React.createElement(ViewLarge, null, React.createElement("div", {
    className: "PageHeader__Content"
  }, React.createElement("div", {
    className: "PageHeader__Description"
  }, showBadge, showDescription), children && React.createElement("div", null, children))))));
};