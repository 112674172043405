import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useLocalization } from '@shared-ui/localization-context';
import { UitkNavigationType, UitkTab, UitkTabs, UitkTabsBackGroundColorType, UitkTabsType } from '@egds/react-core/tabs';
import { accountTabSelected } from '../account.events';
import { BANK_DETAILS_PATH } from '../bank-details/bank-details.controller';
import { PROFILE_PATH } from '../profile/profile.controller';
import { SETTINGS_PATH } from '../settings/settings.controller';
export function AccountTabs() {
  var track = useEgClickstream();
  var navigate = useNavigate();
  var pathname = useLocation().pathname;
  var l10n = useLocalization();
  var tabs = [{
    id: 'profile',
    name: l10n.formatText('account.tab-navigation.profile'),
    path: PROFILE_PATH,
    event: 'affhub_account_profile.selected'
  }, {
    id: 'bank-details',
    name: l10n.formatText('account.tab-navigation.bank_details'),
    path: BANK_DETAILS_PATH,
    event: 'affhub_account_bank_details.selected'
  }, {
    id: 'settings',
    name: l10n.formatText('account.tab-navigation.settings'),
    path: SETTINGS_PATH,
    event: 'affhub_account_settings.selected'
  }].filter(Boolean);
  var handleSelectedTab = function (targetTab, e) {
    e.preventDefault();
    var _a = tabs[targetTab],
      event = _a.event,
      path = _a.path;
    track(accountTabSelected({
      eventName: event
    }));
    navigate(path);
  };
  var selectedTab = React.useMemo(function () {
    return tabs.findIndex(function (tab) {
      // Account for tab serving nested pages (e.g., /app/account/bank-details/create)
      return pathname.startsWith(tab.path);
    });
  }, [pathname]);
  return React.createElement(UitkTabs, {
    tabsType: UitkTabsType.NATURAL,
    backgroundColor: UitkTabsBackGroundColorType.SECONDARY,
    onTabSelect: handleSelectedTab,
    navigationType: UitkNavigationType.JUMPLINK,
    withBorder: true,
    selectedTab: selectedTab
  }, tabs.map(function (tab) {
    return React.createElement(UitkTab, {
      targetURI: tab.path,
      key: tab.id,
      name: tab.name,
      id: tab.id
    });
  }));
}