var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { useCallback } from 'react';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { baseEventFragmentPayload, baseExperienceFragmentPayload, creatorVideoBaseEventFragmentPayload } from './click-stream-utils';
export var EventType;
(function (EventType) {
  EventType["PAGE_VIEW"] = "Page View";
  EventType["INTERACTION"] = "Interaction";
  EventType["IMPRESSION"] = "Impression";
  EventType["OUTCOME"] = "Outcome";
})(EventType || (EventType = {}));
export var APP_NAME = 'eg-affiliates-homepage';
var setTagCommanderVars = function (vars) {
  if (typeof window !== 'undefined') {
    window.tc_vars = __assign(__assign({}, window.tc_vars), vars);
  }
};
var triggerTagCommanderEvent = function (eventName, payload) {
  // Map Clickstream event names to TC event names
  var tcEventMap = {
    pageView: 'pageview'
  };
  var tcEventName = tcEventMap[eventName] || eventName;
  // Tag Commander JS is loaded when the page is loaded, so we need to wait for it to be loaded
  var tryTrigger = function (attempt) {
    var _a, _b;
    if (attempt === void 0) {
      attempt = 1;
    }
    if (typeof window !== 'undefined' && ((_b = (_a = window.tC) === null || _a === void 0 ? void 0 : _a.event) === null || _b === void 0 ? void 0 : _b[tcEventName])) {
      try {
        window.tC.event[tcEventName](payload);
      } catch (error) {
        console.error("Error triggering TC event ".concat(tcEventName, ":"), error);
      }
    } else if (attempt < 3) {
      setTimeout(function () {
        return tryTrigger(attempt + 1);
      }, 500 * attempt);
    } else if (window.tcLoaded) {
      console.error("TC event ".concat(tcEventName, " not found after ").concat(attempt, " attempts"));
    }
  };
  if (typeof window !== 'undefined') {
    if (!window.tcLoaded) {
      console.warn('Tag Commander not loaded yet, queuing event');
    }
    tryTrigger();
  }
};
export var trackTravelCreatorHomeEvents = function (track, eventName, eventType, actionLocation) {
  var openEvent = {
    event: {
      event_version: '1.0.0',
      event_name: eventName,
      event_type: eventType,
      action_location: actionLocation
    },
    experience: {
      page_name: 'travel-creator',
      app_name: APP_NAME,
      app_version: '1.0.0'
    }
  };
  track(openEvent);
  setTagCommanderVars({
    event_page_name: 'travel-creator',
    event_type: eventType,
    event_name: eventName,
    action_location: actionLocation,
    app_name: APP_NAME
  });
  triggerTagCommanderEvent('pageView', {
    eventName: eventName,
    eventType: eventType,
    actionLocation: actionLocation
  });
};
export var affiliateHubHomePresentedEvent = function (track) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_type: EventType.PAGE_VIEW,
      event_name: 'affiliates_hub_home.viewed'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: 'affiliate_hub_home',
      page_name_detailed: 'Affiliate Hub Home'
    })
  };
  track(openEvent);
  setTagCommanderVars({
    event_page_name: 'affiliate_hub_home',
    event_type: EventType.PAGE_VIEW,
    event_name: 'affiliates_hub_home.viewed',
    action_location: 'affiliate_hub_home',
    app_name: APP_NAME
  });
  triggerTagCommanderEvent('pageView', {
    eventName: 'affiliates_hub_home.viewed',
    eventType: EventType.PAGE_VIEW,
    actionLocation: 'affiliate_hub_home'
  });
};
export var headerLogoSelectedEvent = function (track, page) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_name: 'affhub_header_logo.selected'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: page !== null && page !== void 0 ? page : baseExperienceFragmentPayload.page_name
    })
  };
  track(openEvent);
};
export var headerSigninSelectedEvent = function (track, page) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_name: 'affhub_header_sign_in.selected'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: page !== null && page !== void 0 ? page : baseExperienceFragmentPayload.page_name
    })
  };
  track(openEvent);
};
export var homeJoinNowSelectedEvent = function (track, actionLocation) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_name: 'affhub_home_join_now.selected',
      action_location: actionLocation
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: 'affiliate_hub_home'
    })
  };
  track(openEvent);
};
var clickstreamEventBuilder = function (eventOverride, additionalContext) {
  var openEvent = __assign(__assign({
    event: __assign(__assign(__assign({}, creatorVideoBaseEventFragmentPayload), {
      action_reason: sessionStorage.getItem('userType')
    }), eventOverride)
  }, additionalContext && additionalContext), {
    booking: {
      order_id: sessionStorage.getItem('orderNumber'),
      pos_order_reference_number: sessionStorage.getItem('itinerary'),
      transaction_status: 'Success'
    },
    trip: {
      trip_detail_list: [{
        trip_status: sessionStorage.getItem('tripStatus')
      }]
    },
    product_list: [{
      product_id: sessionStorage.getItem('propertyId')
    }]
  });
  return openEvent;
};
export var useCommonClickStreamEvent = function () {
  var track = useEgClickstream();
  var trackEvent = useCallback(function (eventOverride, additionalContext) {
    try {
      var openEvent = clickstreamEventBuilder(eventOverride, additionalContext);
      track(openEvent);
      setTagCommanderVars(__assign(__assign(__assign({}, eventOverride), additionalContext), {
        order_id: sessionStorage.getItem('orderNumber'),
        product_id: sessionStorage.getItem('propertyId'),
        trip_status: sessionStorage.getItem('tripStatus')
      }));
      triggerTagCommanderEvent('customEvent', openEvent);
    } catch (e) {
      // Analytics tracking as a side effect, should not break functionality if failed
    }
  }, [track]);
  return {
    trackEvent: trackEvent
  };
};