import React from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { usePlatformContext } from '@bernie/platform-context';
import { LocalizedText } from '@shared-ui/localization-context';
import { EGDSLink, EGDSList, EGDSListItem } from '@egds/react-core';
export function FooterLinks() {
  var locale = usePlatformContext().context.locale;
  return React.createElement(EGDSList, {
    bullet: "no-bullet",
    orientation: "inline",
    spacing: false
  }, React.createElement(EGDSListItem, {
    key: "listitem-1"
  }, React.createElement(EGDSLink, {
    size: "small"
  }, React.createElement(Link, {
    to: "/commission-terms"
  }, React.createElement(LocalizedText, {
    message: "commercial.page.title"
  }), React.createElement("span", {
    className: "is-visually-hidden"
  }, React.createElement(LocalizedText, {
    message: "opens.new.window"
  }))))), React.createElement(EGDSListItem, {
    key: "listitem-2"
  }, React.createElement(EGDSLink, {
    size: "small"
  }, React.createElement("a", {
    href: "/eg-affiliates/flex-document?dialog=affiliate&locale=".concat(locale),
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.program_terms"
  }), React.createElement("span", {
    className: "is-visually-hidden"
  }, React.createElement(LocalizedText, {
    message: "opens.new.window"
  }))))), React.createElement(EGDSListItem, {
    key: "listitem-3"
  }, React.createElement(EGDSLink, {
    size: "small"
  }, React.createElement("a", {
    href: "/eg-affiliates/flex-document?dialog=privacy-policy&locale=".concat(locale),
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.privacy_policy"
  }), React.createElement("span", {
    className: "is-visually-hidden"
  }, React.createElement(LocalizedText, {
    message: "opens.new.window"
  }))))), React.createElement(EGDSListItem, {
    key: "listitem-4"
  }, React.createElement(EGDSLink, {
    size: "small"
  }, React.createElement("a", {
    href: "/eg-affiliates/flex-document?dialog=cookie-policy&locale=".concat(locale),
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.cookie_policy"
  }), React.createElement("span", {
    className: "is-visually-hidden"
  }, React.createElement(LocalizedText, {
    message: "opens.new.window"
  }))))), React.createElement(EGDSListItem, {
    key: "listitem-5"
  }, React.createElement(EGDSLink, {
    size: "small",
    className: "ot-sdk-show-settings"
  }, React.createElement("button", {
    id: "ot-sdk-btn"
  }))));
}